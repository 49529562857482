import React from 'react';
import {Page, Title, TitleArea, Container} from "../../assets/styles/Main.styled";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import {Policy} from "./PolicyPage.styled";
import HelmetComponent from "../../component/Helmet/Helmet";

const PolicyPage = () => {
  return (
    <Page bgColor={"#F4f4f4"}>
      <HelmetComponent
          title="Политика конфиденциальности"
          content="Политика конфиденциальности"
      />
      <Breadcrumbs/>
      <Container>
        <TitleArea>
          <Title>
            Преимущества
          </Title>
        </TitleArea>
        <Policy>
          <h3>
            Обязанности
          </h3>
          <p>
            Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в
            отношении всей информации, которую данный сайт, на котором размещен текст этой Политики конфиденциальности,
            может получить о Пользователе, а также любых программ и продуктов, размещенных на нем.
          </p>
          <br/>
          <h3>
            Определение терминов
          </h3>
          <p>
            1.1.1. «Администрация сайта» – уполномоченные сотрудники на управления сайтом, действующие от его имени, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
            <br/>        <br/>
            1.1.2. «Персональные данные» — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
            <br/>        <br/>
            1.1.3. «Обработка персональных данных» — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
            <br/>        <br/>
            1.1.4. «Конфиденциальность персональных данных» — обязательное для соблюдения Администрацией сайта требование не допускать их умышленного распространения без согласия субъекта персональных данных или наличия иного законного основания.
            <br/>        <br/>
            1.1.5. «Пользователь сайта (далее Пользователь)» – лицо, имеющее доступ к сайту, посредством сети Интернет и использующее данный сайт для своих целей.
            <br/>        <br/>
            1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
            <br/>        <br/>
            1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
          </p>
          <br/>        <br/>
        </Policy>
      </Container>
    </Page>
  );
};

export default PolicyPage;
