import styled from "styled-components";
import {Button, TitleArea} from "../../assets/styles/Main.styled";
import {baseThemeStyled} from "../../assets/styles/BaseTheme.styled";


export const TitleAreaCatalog = styled(TitleArea).attrs({
  "data-id": "title-area-catalog"
})`
  display: flex;
  align-items: baseline;
  @media ${baseThemeStyled.breakpoints.small} {
    margin-bottom: 0;
  }
`

export const TitleCount = styled.div.attrs({
  "data-id": "title-count"
})`
  padding-left: 7px;
  font: 400 13px/140% ${baseThemeStyled.fontsFamily.Lato};
  position: relative;
  margin-left: 7px;
  &:before {
    content: "";
    left: 0;
    top: 1px;
    height: 80%;
    width: 1px;
    background-color: #979797;
    position: absolute;
  }
`

export const MobileFilterButton = styled(Button).attrs({
  "data-id": "mobile-filter-button"
})`
  margin-left: auto;
  display: none;
  span{
    margin-left: 9px;
    color: #1D293F;
    font: 400 16px/19px ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.small} {
    display: flex;  
  }
`

