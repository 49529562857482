import styled from "styled-components";
import {baseThemeStyled} from "../../../../assets/styles/BaseTheme.styled";

export const MainDignitiesEl = styled.div.attrs({
  "data-id": "main-dignities-el"
})`
  position: relative;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #B6BABD;
  @media ${baseThemeStyled.breakpoints.small} {
    flex-direction: column;
    
  }
`

