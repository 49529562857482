import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Container,
  Title,
  TitleArea,
} from "../../../assets/styles/Main.styled";
import "./style.sass";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import api from "../../../api/api";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ReviewsSlider = () => {
  const [reviews, setReviews] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const init = () => {
    api
      .getReviews()
      .then((res) => {
        // console.log('ITEMS',res.data.ITEMS)

        setReviews(res.data.ITEMS);
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    init();
  }, []);

  if (loading)
    return (
      <SkeletonTheme baseColor="#DCDCDC" highlightColor="#fff">
        <Skeleton style={{ height: "300px" }} count={1} />
      </SkeletonTheme>
    );

  return (
    <div className="section-reviews">
      <Container>
        <TitleArea>
          <Title>Истории в лицах</Title>
        </TitleArea>
        <div className="reviews-area">
          <Swiper
            autoHeight={true}
            loop={false}
            spaceBetween={30}
            slidesPerView={2}
            slidesPerGroup={2}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="swiper-with-pagination"
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              576: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
            }}
          >
            {Object.keys(reviews).map((key) => {
              return (
                <SwiperSlide key={reviews[key].ID}>
                  <div className="review-card">
                    <div className="review-card__header">
                      <div className="review-card__image">
                        <img
                          src={reviews[key].PREVIEW_PICTURE}
                          alt="Сотрудник"
                        />
                      </div>
                      <div className="review-card__info">
                        <p className="name">{reviews[key].NAME}</p>
                        <p className="status">
                          {reviews[key].PROPERTY_POST_VALUE}
                        </p>
                        <p className="stage">
                          {reviews[key].PROPERTY_EXPERIENCE_VALUE}
                        </p>
                      </div>
                    </div>
                    <div className="review-card__body">
                      <p>{reviews[key].PREVIEW_TEXT}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </Container>
    </div>
  );
};

export default ReviewsSlider;
