export const baseThemeStyled = {
  fontsFamily: {
    Lato: "Lato, sans-serif",
    PT: "PT Sans, sans-serif"
  },
  // fontsSize: {
  //   h1: {
  //     size: "44px",
  //     lineHeight: "64px"
  //   },
  //   h2:{
  //     size: "36px",
  //     lineHeight: "38px"
  //   },
  //   banner_h2: {
  //     size: "16px",
  //     lineHeight: "26px"
  //   },
  //   body: {
  //     size: "15px",
  //     lineHeight: "21px"
  //   },
  //   headline: {
  //     size: "21px",
  //     lineHeight: "32px"
  //   },
  //   button_light: {
  //     size: "13px",
  //     lineHeight: "15px"
  //   },
  //   button_red: {
  //     size: "14px",
  //     lineHeight: "20px"
  //   },
  //   button_white:{
  //     size: "14px",
  //     lineHeight: "20px"
  //   },
  //   button_grey: {
  //     size: "14px",
  //     lineHeight: "20px"
  //   },
  //   caption: {
  //     size: "18px",
  //     lineHeight: "32px"
  //   },
  //   headline1: {
  //     size: "21px",
  //     lineHeight: "32px"
  //   },
  //   headline2: {
  //     size: "24px",
  //     lineHeight: "64px"
  //   },
  //   h3: {
  //     size: "18px",
  //     lineHeight: "28px"
  //   },
  //   caption2: {
  //     size: "14px",
  //     lineHeight: "20px"
  //   },
  //   av: {
  //     size: "16px",
  //     lineHeight: "24px"
  //   },
  //   history: {
  //     size: "32px",
  //     lineHeight: "34px"
  //   }
  // },
  colors: {
    lightLightGrey: "#EDEFF4",
    body1: "#F4F4F4",
    body2: "#FFFFFF",
    body3: "#333333",
    blue: "#2F5379",
    grey: "#E4E9EE",
    bl_gr: "#96ACBD",
    body: "#000000",
    pl2: "linear-gradient(89.99deg, rgba(60, 63, 67, 0.82) 50%, rgba(0, 0, 0, 0.435625) 75.25%, rgba(0, 0, 0, 0) 97.07%)",
    link: "#BD0A2F"
  },
  breakpoints:{
    xxl: "only screen and  (min-width: 1980px)",
    extraLarge: "only screen and  (min-width: 1200px)",
    large: "only screen and (max-width: 1199px)", //(max-width: 1199px) and (min-width: 1024px)
    medium: "only screen and (max-width: 1023px)", //(max-width: 1023px) and (min-width: 768px)
    small: "only screen and (max-width: 767px)", //(max-width: 767px) and (min-width: 576px)
    extraSmall: "only screen and (max-width: 575px)",
  }
}
  