import styled from "styled-components";
import {baseThemeStyled} from "../../assets/styles/BaseTheme.styled";
import {css} from "styled-components/macro";
import {ButtonRed} from "../../assets/styles/Main.styled";

export const ContactGrid = styled.div.attrs({
  "data-id": "contacts-grid"
})`
  display: grid;
  padding: 0 0 30px 0;
  gap: 0 30px;
  grid-template-columns: repeat(5, minmax(150px, auto));
  align-items: start;
  @media ${baseThemeStyled.breakpoints.large} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${baseThemeStyled.breakpoints.small} {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-template-columns: repeat(1, 1fr)
  }
`

export const ContactsNameGrid = styled.div.attrs({
  "data-id": "contacts-name-grid"
})`
  grid-column: 1/6;
  @media ${baseThemeStyled.breakpoints.large} {
    grid-column: 1/5
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    grid-column: 1/4
  }
  @media ${baseThemeStyled.breakpoints.small} {
    grid-column: 1/3
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-column: 1/2
  }
`

export const ContactsName = styled.h3.attrs({
  "data-id": "contacts-name"
})`
  font: 700 21px/25px ${baseThemeStyled.fontsFamily.Lato};
  color: #000;
  padding: 0 0 20px 0;
  @media ${baseThemeStyled.breakpoints.small} {
    font: 700 18px/22px ${baseThemeStyled.fontsFamily.Lato};
  }
`

export const ContactsTelGrid = styled.div.attrs({
  "data-id": "contacts-tel-grid"
})`
  grid-column: 1/2;
  display: grid;
  gap: 10px;
  align-items: start;
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-column: 1/2;
    grid-row: 2/3;
  }
`

export const ContactsBlockTitle = styled.p.attrs({
  "data-id": "contacts-block-title"
})`
  font: 700 16px/16px ${baseThemeStyled.fontsFamily.Lato};
  color: #000;
`

const ContactsLinkCss = css`
  font: 400 14px/14px ${baseThemeStyled.fontsFamily.Lato};
  color: #373737;
`

export const ContactsLink = styled.a.attrs({
  "data-id": "contacts-link"
})`
  ${ContactsLinkCss};
`

export const ContactsDesc = styled.p.attrs({
  "data-id": "contacts-desc"
})`
  font: 400 14px/14px ${baseThemeStyled.fontsFamily.Lato};
  color: #626060
`

export const ContactsMailGrid = styled.div.attrs({
  "data-id": "contacts-main-grid"
})`
  grid-column: 2/3;
  display: grid;
  gap: 10px;
  align-items: start;
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-column: 1/2;
    grid-row: 3/4;
  }
`
export const ContactsLinkMail = styled.a.attrs({
  "data-id": "contacts-link-mail"
})`
  ${ContactsLinkCss};
  font: 400 15px/15px ${baseThemeStyled.fontsFamily.Lato};
`

export const ContactsPersonalGrid = styled.div.attrs({
  "data-id": "contacts-personal-grid"
})`
  grid-column: 3/4;
  display: grid;
  gap: 10px;
  align-items: start;
  @media ${baseThemeStyled.breakpoints.small} {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-column: 1/2;
    grid-row: 4/5;
  }
`

export const ContactsText = styled.span.attrs({
  "data-id": "contacts-text"
})`
  ${ContactsLinkCss};
  font: 400 15px/17px ${baseThemeStyled.fontsFamily.Lato};
`

export const ContactsAddressBlock = styled.div.attrs({
  "data-id": "contacts-address-block"
})`
  margin-bottom: 30px;
  display: grid;
  padding: 5px;
  background: #FFFFFF;
  border-radius: 3px;
  gap: 0 30px;
  grid-template-columns: repeat(5, 1fr);
  align-items: start;
  @media ${baseThemeStyled.breakpoints.large} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${baseThemeStyled.breakpoints.small} {
    grid-template-columns: 1fr;
    gap: 20px;
    grid-template-rows: repeat(2, 1fr);
    padding: 0;
    margin-left: -16px;
    margin-right: -16px;
    border-radius: 0;
    margin-bottom: 15px;
  }
`

export const ContactsAddressWrapper = styled.div.attrs({
  "data-id": "contacts-address-wrapper"
})`
  grid-column: 1/3;
  padding: 15px;
  @media ${baseThemeStyled.breakpoints.large} {
    grid-column: 1/3
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    grid-column: 1/3
  }
  @media ${baseThemeStyled.breakpoints.small} {
    grid-column: 1/2
  }
`

export const ContactsAddressTitle = styled.h3.attrs({
  "data-id": "contacts-address-title"
})`
  font: 700 18px/140% ${baseThemeStyled.fontsFamily.Lato};
  color: #000;
  padding-bottom: 15px;
`

export const ContactsAddressList = styled.ul.attrs({
  "data-id": "contacts-address-list"
})`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
`

export const ContactsAddressItem = styled.li.attrs({
  "data-id": "contacts-address-item"
})`
  display: flex;
  align-items: center;
`

export const ContactsAddressItemIcon = styled.div.attrs({
  "data-id": "contacts-address-item-icon"
})`
  margin-right: 10px;
`


export const ContactsAddressText = styled.div.attrs({
  "data-id": "contacts-address-text"
})`
  font: 400 14px/16px ${baseThemeStyled.fontsFamily.Lato};
  color: #000;
  padding-right: 10px;
  position: relative;
  display: inline-block;

  span {
    font-weight: 800;
  }

  p {
    cursor: pointer;
    font: 400 12px/130% ${baseThemeStyled.fontsFamily.Lato};
    text-decoration-line: underline;
    color: #BD0A2F;
    display: inline;
  }
`

export const ContactsMapBlock = styled.div.attrs({
  "data-id": "contacts-map-block"
})`
  grid-column: 3/6;
  align-self: stretch;
  //background: black;
  justify-self: stretch;
  //height: 220px;
  @media ${baseThemeStyled.breakpoints.small} {
    grid-column: 1/2;
    grid-row: 2/3;
    //height: 175px;
    height: 100%;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-column: 1/2;
    grid-row: 2/3;
    //height: 220px;
  }
`

export const ContactFormArea = styled.div.attrs({
  "data-id": "contact-form-area"
})`
  display: flex;
  align-items: baseline;
  @media ${baseThemeStyled.breakpoints.small}{
    flex-direction: column;
  }
`

export const ContactFormText = styled.p.attrs({
  "data-id": "contact-form-text"
})`
  width: 100%;
  font: 400 15px/18px ${baseThemeStyled.fontsFamily.Lato};
  display: flex;
  align-items: center;
  padding-right: 15px;
  @media ${baseThemeStyled.breakpoints.medium}{
    font: 400 14px/17px ${baseThemeStyled.fontsFamily.Lato};
    padding-right: 10px;
  }
  @media ${baseThemeStyled.breakpoints.small}{
    padding-right: 0px;
    margin-bottom: 15px;
  }
`

export const ContactForm = styled.form.attrs({
  "data-id": "contact-form"
})`
  display: flex;
  flex-wrap: wrap;
  width: 430px;
  flex: 0 0 430px;
  justify-content: space-between;
  @media ${baseThemeStyled.breakpoints.small}{
    width: 100%;
    flex: 1 1 100%;
  }
`

const SizeCss = css`
  max-width: 210px;
  width: 100%;
  margin-bottom: 9px;
  @media ${baseThemeStyled.breakpoints.small}{
    max-width: 100%;
    flex: 0 0 49%;
    margin-bottom: 12px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    width: 100%;
    flex: 1 1 100%;
  }
`

export const ContactFormInputGroup = styled.div.attrs({
  "data-id": "contact-form-input-group"
})`
  order: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  ${SizeCss};
  .form__input{
    color: #333333;
    font: 400 15px/18px ${baseThemeStyled.fontsFamily.Lato};
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #B6BABD;
  }
`
export const ContactButton= styled(ButtonRed).attrs({
  "data-id": "contact-button"
})<{disabled: boolean}>`
  order: 2;
  color: #fff;
  ${SizeCss};
  height: 30px;
  ${props => {
    if(props.disabled){
      return css`
        opacity: 0.5;
        pointer-events: none;
       
        &:hover{
          cursor: not-allowed;
        }
      `
    }
  }}
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    margin-bottom: 0;
    order: 4;
  }
`

export const ContactCheckbox= styled.div.attrs({
  "data-id": "contact-checkbox"
})`
  order: 3;
  [data-id="checkbox-square"] {
    background-color: #fff;
  }
  [data-id="checkbox-text"] {
    font: 400 15px / 130% ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    margin-bottom: 15px;
  }
`
