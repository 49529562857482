import React from "react";
import {
  MainBannerContainerTitle,
  MainBannerImage,
  MainBannerSubTitle,
  MainBannerTitle,
  MainBannerTitleArea,
  MainChel,
  MainChelContainer,
  MainContainer,
  MainSection,
} from "./MainBanner.styled";
import MainSearch from "../main-search/MainSearch";
import MainTag from "../main-tag/MainTag";
import Chel from "../../../../assets/images/chel.png";

const MainBanner = () => {
  return (
    <MainSection>
      <MainBannerImage />

      <MainBannerContainerTitle>
        <MainBannerTitleArea>
          <MainBannerTitle>
            Приглашаем <br /> на работу
            <br /> в МТК <br /> Росберг!
          </MainBannerTitle>
          <MainBannerSubTitle>
            На сегодняшний день у нас работает около 450 сотрудников в 20
            подразделениях по всей стране. Наш опыт работы более 14 лет.
          </MainBannerSubTitle>
        </MainBannerTitleArea>
      </MainBannerContainerTitle>
      <MainContainer>
        <MainSearch />
        <MainTag />
      </MainContainer>
      <MainChelContainer>
        <MainChel src={Chel} height="100%" />
      </MainChelContainer>
    </MainSection>
  );
};

export default MainBanner;
