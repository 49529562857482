import Icon8 from "../assets/images/icon8.svg";
import Icon10 from "../assets/images/icon10.svg";
import Icon11 from "../assets/images/breadcrumbs-arrow.png"
import Image1 from "../assets/images/Advantages1.jpg"
import Image2 from "../assets/images/Advantages2.jpg"
import Image3 from "../assets/images/Advantages3.jpg"
import Image4 from "../assets/images/Advantages4.jpg"
import Image5 from "../assets/images/Advantages5.jpg"
import Image6 from "../assets/images/Advantages6.jpg"
import Image7 from "../assets/images/Advantages7.jpg"
import Image8 from "../assets/images/Advantages8.jpg"
import Image9 from "../assets/images/Advantages9.jpg"
import Hh from "../assets/images/hh.png"
import Avito from "../assets/images/avito.png"
import Super from "../assets/images/super.png"
import DignitiesImage from "../assets/images/dignities.jpg"
import DignitiesImage1 from "../assets/images/dignities1.jpg"
import DignitiesImage2 from "../assets/images/dignities2.jpg"
import DignitiesImage3 from "../assets/images/dignities3.jpg"
import FormSectionImage1 from "../assets/images/form-section-image.png"
import SelectIcon from "../assets/images/select.png"

export const IMAGES = {
  Icon8,
  Icon10,
  Icon11,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Hh,
  Avito,
  Super,
  DignitiesImage,
  DignitiesImage1,
  DignitiesImage2,
  DignitiesImage3,
  FormSectionImage1,
  SelectIcon
};
