/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'

export default {
  getAllVacancies : (link="") => axios.get(`/api/hr/find/?${link}`),
  getVacancy : (id) => axios.get(`/api/hr/find/?COUNT=1&ID=${id}`),
  // getFields : () => axios.get('/api/hr/getFields/'),
  getFilters : () => axios.get('/api/hr/filter/'),
  // getVacancyFilter: (link) => axios.get(`/api/hr/find/?${link}`),
  // getVacancyName: (name) => axios.get(`/api/hr/find/?FIND=${name}`),
  getInterview: (data) => axios.post(`/api/hr/interview/`, data),
  getBrands: () => axios.post(`/api/hr/brands/`),
  getReviews: () => axios.get(`/api/hr/reviews/get/`),

  // getAllVacancies: () => '/api/hr/find/',
}
