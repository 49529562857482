import styled from "styled-components";
import {baseThemeStyled} from "../../assets/styles/BaseTheme.styled";

export const HistoryTextGreen = styled.p.attrs({
    "data-id": "history-text-green"
})`
  font: 600 18px/24px ${baseThemeStyled.fontsFamily.Lato};
  color: #217724;
  margin-bottom: 56px;
  @media ${baseThemeStyled.breakpoints.medium} {
      font: 600 16px/23px ${baseThemeStyled.fontsFamily.Lato};
      margin-bottom: 23px;
  }
   @media ${baseThemeStyled.breakpoints.medium} {
      font: 600 16px/23px ${baseThemeStyled.fontsFamily.Lato};
      margin-bottom: 22px;
  }
    @media ${baseThemeStyled.breakpoints.small} {
      font: 600 15px/23px ${baseThemeStyled.fontsFamily.Lato};
      margin-bottom: 28px;
  }
  @media ${baseThemeStyled.breakpoints.small} {
    font: 600 15px/18px ${baseThemeStyled.fontsFamily.Lato};
    margin-bottom: 28px;
  }
`

export const HistoryStatArea = styled.div.attrs({
    "data-id": "history-stat-area"
})`
  display: flex;
  justify-content: center;
  padding-bottom: 26px;
  @media ${baseThemeStyled.breakpoints.large} {
     padding-bottom: 20px;
  }
    @media ${baseThemeStyled.breakpoints.medium} {
      margin-bottom: 15px;
  }
    @media ${baseThemeStyled.breakpoints.small} {
      padding-bottom: 12px;
  }
 
`
export const HistoryStat = styled.div.attrs({
    "data-id": "history-stat"
})`
  display: flex;
  @media ${baseThemeStyled.breakpoints.extraSmall} {
      flex-direction: column;
  }
`

export const HistoryStatCol = styled.div.attrs({
    "data-id": "history-stat-col"
})`
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #B6BABD;
  ${HistoryStat} > &:last-child{
    border-right: none;
  }
  @media ${baseThemeStyled.breakpoints.medium} {
     padding: 0px 30px;
   }
   @media ${baseThemeStyled.breakpoints.small} {
   padding: 0px 20px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
   border-right: none;
   margin-bottom: 15px
  }
  .red,.black{
      text-align: center;
      font: 700 42px/34px ${baseThemeStyled.fontsFamily.Lato};
      margin-bottom: 8px;
      @media ${baseThemeStyled.breakpoints.medium} {
          font: 700 37px/34px ${baseThemeStyled.fontsFamily.Lato};
          margin-bottom: 18px;
        };
      @media ${baseThemeStyled.breakpoints.small} {
          font: 700 28px/34px ${baseThemeStyled.fontsFamily.Lato};
          margin-bottom: 12px;
        };
       @media ${baseThemeStyled.breakpoints.extraSmall} {
         margin-bottom: 5px;
        font: 700 37px/34px ${baseThemeStyled.fontsFamily.Lato};
      }
  }
  .red{
    color: #BD0A2F
  }
  .black{
    color: #38454E;
  }
  .text{
  text-align: center;
    text-transform: uppercase;
    color: #38454E;
    font: 800 14px/32px ${baseThemeStyled.fontsFamily.Lato};
    @media ${baseThemeStyled.breakpoints.medium} {
     font: 800 12px/14px ${baseThemeStyled.fontsFamily.Lato};
    }
  }
`

export const HistoryText = styled.p.attrs({
    "data-id": "text"
})`
  font: 400 16px/20px ${baseThemeStyled.fontsFamily.Lato};
  color: #000000;
  padding-bottom: 70px;
  @media ${baseThemeStyled.breakpoints.large} {
    padding-bottom: 30px;
  }
   @media ${baseThemeStyled.breakpoints.small} {
     font: 400 14px/18px ${baseThemeStyled.fontsFamily.Lato};
  }
`

export const HistoryBrandBlock = styled.div.attrs({
    "data-id": "history-brand-block"
})`
  .main-brand-slider-area{
    padding: 20px 0 67px 0;
    @media ${baseThemeStyled.breakpoints.large} {
     padding: 20px 0 55px 0;
    }
     @media ${baseThemeStyled.breakpoints.medium} {
         padding: 20px 0 50px 0;
      }
  
  };
  [data-id="lending-title-area"]{
     margin-bottom: 38px;
     @media ${baseThemeStyled.breakpoints.large} {
         margin-bottom: 55px;
      }
     @media ${baseThemeStyled.breakpoints.medium} {
         margin-bottom: 20px;
      }
  };
    [data-id="lending-title"]{
     margin-bottom: 0;
     font: 700 36px/43px ${baseThemeStyled.fontsFamily.Lato};

  }
`

export const HistoryWork = styled.div.attrs({
    "data-id": "history-work"
})`
  padding: 45px 0;
  @media ${baseThemeStyled.breakpoints.large} {
   padding: 25px 0 50px 0;
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    padding: 20px 0 35px 0;
   }
  @media ${baseThemeStyled.breakpoints.small} {
     padding: 20px 0 10px 0;
  }
`

export const HistoryWorkTitle = styled.h5.attrs({
    "data-id": "history-work-title"
})`
  text-align: center;
  color: #2F5379;
  font: 600 32px/32px ${baseThemeStyled.fontsFamily.Lato};
  margin-bottom: 49px;
  @media ${baseThemeStyled.breakpoints.large} {
     margin-bottom: 38px;
  }
   @media ${baseThemeStyled.breakpoints.medium} {
     margin-bottom: 23px;
     font: 600 27px/32px ${baseThemeStyled.fontsFamily.Lato};
  }
   @media ${baseThemeStyled.breakpoints.small} {
     margin-bottom: 27px;
     font: 600 21px/27px ${baseThemeStyled.fontsFamily.Lato};
  };
    @media ${baseThemeStyled.breakpoints.extraSmall} {
     margin-bottom: 30px;
     letter-spacing: -0.5px;
  }
`
export const HistoryFlags = styled.div.attrs({
    "data-id": "history-flags"
})` 
  justify-content: space-between;
  display: flex;
  @media ${baseThemeStyled.breakpoints.small} {
    flex-wrap: wrap;
    justify-content: center;
    width: 290px;
    margin: 0 auto;
  } 
`

export const HistoryFlag = styled.div.attrs({
    "data-id": "history-flag"
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${baseThemeStyled.breakpoints.small} {
    margin-bottom: 18px;
    flex: 0 0 33%
  }
  span{
    text-align: center;
    text-transform: uppercase;
    color: #38454E;
    margin-top: 16px;
    font: 800 16px/130% ${baseThemeStyled.fontsFamily.Lato};
    @media ${baseThemeStyled.breakpoints.large} {
       margin-top: 13px;
     }
    @media ${baseThemeStyled.breakpoints.small} {
      font: 700 12px/15px ${baseThemeStyled.fontsFamily.Lato};
    }
  }
  
  @media ${baseThemeStyled.breakpoints.large} {
    img{
      width: 168px;
    }
    span{
      margin-top: 13px;
    }
  }
  
  @media ${baseThemeStyled.breakpoints.small} {
    span{
      margin-top: 7px;
    }
  }
  
  @media ${baseThemeStyled.breakpoints.medium} {
    img{
      width: 125px;
    }
  }
  @media ${baseThemeStyled.breakpoints.small} {
    img{
      width: 73px;
    }
  }   
`
