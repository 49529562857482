/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {Page, Container, TitleArea, Title} from 'assets/styles/Main.styled';
import  {useContext, useEffect, useState} from 'react';
import {
  VacancieAddress,
  VacancieButtonLink,
  VacancieButtons,
  VacancieButtonShow,
  VacancieButtonWrite,
  VacancieContactArea,
  VacancieContactIcon, VacancieContactLink,
  VacancieInfo,
  VacancieLi,
  VacancieMore,
  VacancieOther,
  VacancieOtherArea,
  VacancieOtherCol,
  VacancieOtherLi,
  VacancieOtherTitle,
  VacancieOtherUl,
  VacanciePageEl,
  VacanciePriceArea,
  VacancieTitle,
  VacanciePageSliderArea
} from "./VacanciePage.styled";
import {CardVacancyCity, CardVacancyPoint} from "../../component/card-vacancy/CardVacancy.styled";
import {IconSvg} from "../../utils/shared";
import {IMAGES} from "../../constants/Images";
import {useNavigate, useParams} from "react-router-dom";
import {InterviewContext} from "../../context/interview";
import {useTypedSelector} from "../../hooks/useTypedSelection";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import api from "../../api/api";
import {getAllVacancies} from "../../redux/Vacancies/VacanciesSlice";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import CardVacancyList from "../../component/card-vacancy/CardVacancyList";
import HelmetComponent from "../../component/Helmet/Helmet"

const VacanciePage = () => {

  const params = useParams()

  const [isOpenContact, setIsOpenContact] = useState(false)

  const {formModal, setFormModal} = useContext(InterviewContext)

  const [vacancy, setVacancy] = useState({})

  const [loading, setLoading] = useState(true)

  const [showContact, setShowContact] = useState(false)

  const {vacancies, status} = useTypedSelector(
    (state) => state.vacancies
  );

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const fetchData = () => {
    setLoading(true)
    api.getVacancy(params.id).then(resp => {
      const v = resp.data.ITEMS[params.id]
      if (!v) {
        navigate(`/error`);
        return
      }
      setVacancy(v)
      setLoading(false)
    }).catch(error => {
      // console.log('resp', error)
    })
    dispatch(getAllVacancies(""));
  }

  useEffect(() => {
    fetchData()
  }, [params])

  return (
    <Page bgColor={"#fff"} style={{paddingBottom: 0}}>
      <HelmetComponent
          title={vacancy?.NAME}
          content={vacancy?.NAME}
      />
      <Container>
        <TitleArea line>
          <Title>
            Вакансия
          </Title>
        </TitleArea>
        {loading ?
          <SkeletonTheme baseColor="#f3f3f3" highlightColor="#ecebeb">
            <Skeleton className="vacancy-skeleton" count={1}/>
          </SkeletonTheme>
          :
          <VacanciePageEl>
            <VacancieTitle>
              {vacancy?.NAME}
            </VacancieTitle>
            {vacancy?.PAY_TEXT
              &&
              <VacanciePriceArea>
                <span>
                  {vacancy?.PAY_TEXT} {vacancy?.EXTRADITION}
                </span>
              </VacanciePriceArea>
            }
            <VacancieAddress>
              <CardVacancyPoint>
                {IconSvg.contact.address("#BD0A2F")}
              </CardVacancyPoint>
              <CardVacancyCity>
                {vacancy?.CITY?.VALUE}
              </CardVacancyCity>
            </VacancieAddress>
            <VacancieInfo
              dangerouslySetInnerHTML={{__html: vacancy?.DETAIL_TEXT}}/>

            <VacancieMore>
              <VacancieLi>
                <strong>Оформление:</strong> {vacancy?.DECORATION.VALUE}
              </VacancieLi>
              <VacancieLi>
                <strong>График работы:</strong> {vacancy?.WORK_SCHEDULE}
              </VacancieLi>
              <VacancieLi>
                <strong>Выплаты:</strong> {vacancy?.PAYMENTS}
              </VacancieLi>
            </VacancieMore>

            <VacancieOtherArea>

              <VacancieOther>
                {vacancy?.RESPONSIBILITIES
                  &&
                  <VacancieOtherCol>
                    <VacancieOtherTitle>
                      Обязанности:
                    </VacancieOtherTitle>
                    <VacancieOtherUl>
                      {vacancy.RESPONSIBILITIES.map((item, index) => {
                        return (
                          <VacancieOtherLi key={index}>
                            {item}
                          </VacancieOtherLi>
                        )
                      })}
                    </VacancieOtherUl>
                  </VacancieOtherCol>
                }
                {vacancy?.REQUIREMENTS
                  &&
                  <VacancieOtherCol>
                    <VacancieOtherTitle>
                      Требования:
                    </VacancieOtherTitle>
                    <VacancieOtherUl>
                      {vacancy.REQUIREMENTS.map((item, index) => {
                        return (
                          <VacancieOtherLi
                            key={index}>
                            {item}
                          </VacancieOtherLi>
                        )
                      })}
                    </VacancieOtherUl>
                  </VacancieOtherCol>
                }

              </VacancieOther>

            </VacancieOtherArea>

            <VacancieButtons>

              <VacancieButtonWrite
                  onClick={()=>setFormModal(true)}>
                {IconSvg.menu.write_icon()}
                Записаться на собеседование
              </VacancieButtonWrite>

              <VacancieButtonShow onClick={()=> setIsOpenContact(!isOpenContact)}>
                { isOpenContact ? "Скрыть контакты" : "Показать контакты"}
              </VacancieButtonShow>

              {vacancy?.HEADHUNTER
                &&
                <VacancieButtonLink to={vacancy.HEADHUNTER}>
                  <img src={IMAGES.Hh} alt="hh"/>
                </VacancieButtonLink>
              }

              {vacancy?.AVITO
                &&
                <VacancieButtonLink to={vacancy.AVITO}>
                  <img src={IMAGES.Avito} alt="avito"/>
                </VacancieButtonLink>
              }

              {vacancy?.SUPERJOBS
                &&
                <VacancieButtonLink to={vacancy.SUPERJOBS}>
                  <img src={IMAGES.Super} alt="super"/>
                </VacancieButtonLink>
              }

            </VacancieButtons>

            {isOpenContact
              &&
              <VacancieContactArea>

                <VacancieOther>
                  <VacancieOtherCol>
                    <VacancieOtherTitle>
                      Контакты:
                    </VacancieOtherTitle>
                    <VacancieOtherUl>
                      <VacancieOtherLi>
                        <VacancieContactIcon>
                          {IconSvg.contact.address("#BD0A2F")}
                        </VacancieContactIcon>
                        {vacancy?.CONTACTS.NAME}
                      </VacancieOtherLi>
                      <VacancieOtherLi>
                        <VacancieContactIcon>
                        </VacancieContactIcon>
                        {vacancy?.CONTACTS.WEEKDAY}
                      </VacancieOtherLi>
                      <VacancieOtherLi>
                        <VacancieContactIcon>
                        </VacancieContactIcon>
                        сб {vacancy?.CONTACTS.WEEKEND}
                      </VacancieOtherLi>
                    </VacancieOtherUl>
                  </VacancieOtherCol>

                  <VacancieOtherCol>
                    <VacancieOtherTitle>
                      &nbsp;
                    </VacancieOtherTitle>
                    <VacancieOtherUl>
                      {vacancy && Object.keys(vacancy?.CONTACTS.PHONE).map((key, index) => {
                        return (
                          <VacancieContactLink key={index}
                                               href={`tel:${vacancy?.CONTACTS?.PHONE[key].replace(/\D/g, '').substr(0, 11)}`}>
                            <VacancieContactIcon>
                              {IconSvg.contact.phone("#BD0A2F")}
                            </VacancieContactIcon>
                            {vacancy?.CONTACTS?.PHONE[key]}
                          </VacancieContactLink>
                        )
                      })}

                      {vacancy && Object.keys(vacancy?.CONTACTS.MAIL).map((key, index) => {
                        return (
                          <VacancieContactLink href={`mailto:${vacancy?.CONTACTS.MAIL[key]}`}>
                            <VacancieContactIcon>
                              {IconSvg.contact.mail("#BD0A2F")}
                            </VacancieContactIcon>
                            {vacancy?.CONTACTS.MAIL[key]}
                          </VacancieContactLink>
                        )
                      })}

                    </VacancieOtherUl>
                  </VacancieOtherCol>

                </VacancieOther>

              </VacancieContactArea>
            }
          </VacanciePageEl>
        }
      </Container>
      <VacanciePageSliderArea bgColor={"#f4f4f4"}>
        <Container>
          <TitleArea>
            <Title>
              Другие вакансии
            </Title>
          </TitleArea>

        </Container>
        <CardVacancyList
          status={status}
          vacancies={vacancies.slice(0, 5)}
        />
      </VacanciePageSliderArea>
    </Page>
  );
};

export default VacanciePage;
