import styled from "styled-components";
import {Container, Section} from "../../../../assets/styles/Main.styled";
import {baseThemeStyled} from "../../../../assets/styles/BaseTheme.styled";
import Banner from "../../../../assets/images/main-banner.jpg";
import BannerEffect from "../../../../assets/images/main-banner-effect.png";

export const MainSection = styled(Section).attrs({
  "data-id": 'main-section'
})`
  padding: 100px 0 0 0;
  min-height: 602px;
  display: flex;
  flex-direction: column;
  //overflow: hidden;
    // @media ${baseThemeStyled.breakpoints.xxl}{
  //   height: 700px
  // }
  @media ${baseThemeStyled.breakpoints.large} {
    min-height: 407px;
    padding: 35px 0 0 0;
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    min-height: 370px;
    padding: 30px 0 0 0;
  }
  @media ${baseThemeStyled.breakpoints.small} {
    min-height: 370px
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    min-height: 305px;
    padding: 24px 0 0 0;
  }
`
export const MainBannerContainerTitle = styled(Container).attrs({
  "data-id": 'main-banner-container-title'
})`
  z-index: 1;
  @media ${baseThemeStyled.breakpoints.large} {
    padding-left: 0
  }
`



export const MainBannerImage = styled.div.attrs({
  "data-id": 'main-banner-image'
})`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url(${Banner});
  background-position: center;
  background-size: cover;
  @media ${baseThemeStyled.breakpoints.medium} {
    background-position: 0 0 ;
  }
`

export const MainChelContainer = styled(Container).attrs({
  "data-id": 'main-chel-container'
})`
  width: 100%;
  //border: 1px solid red;
  //height: 1px;
  margin-top:  auto;
`

export const MainChel = styled.img.attrs({
  "data-id": "main-chel"
})`
  width: 489px;
  height: 517px;
  object-fit: cover;
  object-position: top;
  position: absolute;
  bottom: -0px;
  right: 18px;
  z-index: 2;
  @media ${baseThemeStyled.breakpoints.large} {
    height: 375px;
    bottom: 0px;
    right: 19px;
    width: 370px;
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    height: 350px;
  }
  @media ${baseThemeStyled.breakpoints.small} {
    right: -100px;
  }

  @media ${baseThemeStyled.breakpoints.extraSmall} {
    height: 260px;
    width: 250px;
    right: 0 ;
    left: 50%;
  }
`

export const MainBannerTitleArea = styled.div.attrs({
  "data-id": 'main-banner-main-title-area'
})`
  padding: 35px 40px 40px 40px;
  margin-bottom: 30px;
  max-width: 760px;
  width: 100%;
  background-image: url(${BannerEffect});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media ${baseThemeStyled.breakpoints.large} {
    padding: 20px 40px 30px 84px;
    margin-bottom: 24px;
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    padding: 12px 40px 20px 66px;
    max-width: 100%;
  }
  @media ${baseThemeStyled.breakpoints.small} {
    padding: 12px 40px 20px 16px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    padding: 13px 40px 24px 16px;
  }
`

export const MainBannerTitle = styled.h1.attrs({
  "data-id": 'main-banner-main-title'
})`
  color: #fff;
  font: 800 48px/62px ${baseThemeStyled.fontsFamily.Lato};
  margin-bottom: 24px;
  max-width: 524px;
  width: 100%;

  br {
    display: none;
  }

  @media ${baseThemeStyled.breakpoints.large} {
    font: 800 38px/46px ${baseThemeStyled.fontsFamily.Lato};
    margin-bottom: 15px;
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    max-width: 350px;
    font: 800 32px/42px ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.small} {
    margin-bottom: 17px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    font: 800 24px/34px ${baseThemeStyled.fontsFamily.Lato};
    max-width: 158px;
    margin-bottom: 0px;
    br {
      display: inline-block;
    }
  }

`
export const MainBannerSubTitle = styled.h1.attrs({
  "data-id": 'main-banner-sub-main-title'
})`
  color: #fff;
  font: 700 16px/26px ${baseThemeStyled.fontsFamily.Lato};
  max-width: 555px;
  width: 100%;
  @media ${baseThemeStyled.breakpoints.medium} {
    max-width: 400px;
    font: 700 15px/21px ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.small} {
    max-width: 340px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    display: none;
  }
`

export const MainContainer = styled(Container).attrs({
  "data-id": "main-container"
})`
  z-index: 3;
  position: relative;
`
