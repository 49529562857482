import styled from "styled-components/macro";
import {baseThemeStyled} from "../../../../assets/styles/BaseTheme.styled";
import {ITitle} from "../../../../utils/types"
import {Link} from "react-router-dom";

export const LendingTitleArea = styled.div.attrs({
  "data-id": "lending-title-area"
})<ITitle>`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${props => props.mb + "px"};
  @media ${baseThemeStyled.breakpoints.large} {
    margin-bottom: ${props => props.mb + "px"};
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    margin-bottom: ${props => props.md_mb + "px"};
  }
  @media ${baseThemeStyled.breakpoints.small} {
    margin-bottom: ${props => props.md_mb + "px"};
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    margin-bottom: ${props => props.esm_mb + "px"};
  }
`

export const LendingTitle = styled.h2.attrs({
  "data-id": "lending-title"
})`
  color: #38454E;
  letter-spacing: -1px;
  font: 700 44px/64px ${baseThemeStyled.fontsFamily.Lato};
  margin-bottom: 10px;
  @media ${baseThemeStyled.breakpoints.large} {
    font: 700 36px/43px ${baseThemeStyled.fontsFamily.Lato};
    letter-spacing: 0px;
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    font: 700 32px/34px ${baseThemeStyled.fontsFamily.Lato};
    margin-bottom: 5px;
  }
  @media ${baseThemeStyled.breakpoints.small} {
    font: 700 27px/32px ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    font: 700 24px/28px ${baseThemeStyled.fontsFamily.Lato};
    margin-bottom: 0px;
  }
`

export const LendingTitleLink =styled(Link).attrs({
  "data-id": "lending-title-link"
})`
  color: #BD0A2F;
  text-decoration-line: underline;
  font: 400 14px/28px ${baseThemeStyled.fontsFamily.Lato};
  @media ${baseThemeStyled.breakpoints.small} {
    font: 400 13px/16px ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.medium} {
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    display: none;
  }
`
