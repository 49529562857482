import React, {createContext, useState} from 'react';

export const InterviewContext = createContext()

export const InterviewContextProvider = ({children}) => {

    const [formModal, setFormModal] = useState(false)
    const [showCommentInput, setShowCommentInput] = useState(false);
    const [fio, setFio] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [birthday, setBirthday] = useState('');
    const [email, setEmail] = useState('');
    const [position, setPosition] = useState('');
    const [file, setFile] = useState('');
    const [comment, setComment] = useState('');
    const [errorFio, setErrorFio] = useState('');
    const [errorPhone, setErrorPhone] = useState('');
    const [errorComment, setErrorComment] = useState('');
    const [errorBirthday, setErrorBirthday] = useState('');
    const [errorCity, setErrorCity] = useState('');

    const [agreement, setAgreement] = useState(false)

    const [modalSuccess, setModalSuccess] = useState(false);
    //при отправки формы становится true , то вместо кнопки в форме появляется загрузка
    //становится false когда приходит ответ от сервера
    const [loadingSuccess, setLoadingSuccess] = useState(false)
    //если true то скрывается форма и появляется заглушка с успешной отправкой формы
    const [success, setSuccess] = useState(false)

    const resetFormInterview = () => {
        setFio('')
        setPhone('')
        setBirthday('')
        setCity('')
        setEmail('')
        setPosition('')
        setFile('')
        setAgreement(false)
        setComment('')
        setErrorPhone('')
        setErrorFio('')
        setErrorBirthday('')
        setErrorCity('')
        setLoadingSuccess(false)
    }

    return (
        <InterviewContext.Provider value={{
            showCommentInput, setShowCommentInput,
            fio, setFio,
            phone, setPhone,
            birthday, setBirthday,
            email, setEmail,
            position, setPosition,
            file, setFile,
            agreement, setAgreement,
            success, setSuccess,
            comment, setComment,
            loadingSuccess, setLoadingSuccess,
            errorFio, setErrorFio,
            errorPhone, setErrorPhone,
            errorComment, setErrorComment,
            errorBirthday, setErrorBirthday,
            city, setCity,
            resetFormInterview,
            errorCity, setErrorCity,
            formModal, setFormModal,
            modalSuccess, setModalSuccess
        }}>
            {children}
        </InterviewContext.Provider>
    )

}
