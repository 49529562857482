import React, { useState } from "react";
import {
  Container,
  Title,
  TitleArea,
} from "../../../assets/styles/Main.styled";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import "./style.sass";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import L1 from "../../../assets/images/L1.jpg";
import L2 from "../../../assets/images/L2.jpg";
import L3 from "../../../assets/images/L3.jpg";
import L4 from "../../../assets/images/L4.jpg";
import L5 from "../../../assets/images/L5.jpg";
import L6 from "../../../assets/images/L6.jpg";
import L7 from "../../../assets/images/L7.jpg";

const SliderLife = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const images = [L1, L2, L3, L4, L5, L6, L7];
  return (
    <div className="section-reviews section-life">
      <Container>
        <TitleArea>
          <Title>Наша жизнь</Title>
        </TitleArea>

        <Swiper
          // style={{
          //   "--swiper-navigation-color": "#fff",
          //   "--swiper-pagination-color": "#fff",
          // }}
          loop={false}
          spaceBetween={0}
          navigation={false}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Thumbs]}
          className="mySwiper2"
        >
          {images.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <img src={item} alt="img" />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={false}
          spaceBetween={20}
          slidesPerView={6}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs, Pagination]}
          className="mySwiper swiper-with-pagination"
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
              freeMode: false,
            },
            576: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              freeMode: true,
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              freeMode: true,
              slidesPerView: 6,
              spaceBetween: 30,
            },
          }}
        >
          {images.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <img src={item} alt="img" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </div>
  );
};

export default SliderLife;
