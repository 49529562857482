/* eslint-disable array-callback-return */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "api/api";
import { CategoryDic } from "utils/Constants";
import { NormalizeFilterView } from "utils/Helpers";
import { EStatus } from "utils/types";

export const getFilters = createAsyncThunk(
  "filters/getFilters",
  async (query: string) => {
    const response: any = await api.getFilters();
    if (query) {
      const split = query.split("&");
      const arrNumbersFilter = split.map((el: string) => +el.split("=")[1]);
      const data = NormalizeFilterView(response.data).map((filter: IFilter) => {
        let newFilter = {
          type: filter.type,
          items: [] as IFilterItem[],
        };
        filter.items.forEach((f: IFilterItem) => {
          if (arrNumbersFilter.indexOf(f.id) !== -1) {
            f.check = true;
          }
          if (f) {
            newFilter.items.push(f);
          }
        });

        return newFilter;
      });
      return data;
    } else {
      return NormalizeFilterView(response.data);
    }
  }
);

export interface IFilterItem {
  value: string;
  id: number;
  count: number;
  check?: boolean;
}

export interface IFilter {
  type: string | "SECTION" | "CITY" | "CHART" | "DECORATION";
  items: IFilterItem[];
}

export interface IFiltersState {
  filters: any;
  status: EStatus;
  query: string;
}

const initialState: IFiltersState = {
  filters: [],
  status: EStatus.idle,
  query: "",
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    checkCategory(state, action: PayloadAction<any>) {
      state.filters.forEach((filter: any, index: number) => {
        filter.items.forEach((ct: any) => {
          const query = `${CategoryDic[filter.type]}=${ct.id}`;
          if (action.payload === null) {
            ct.check = false;
            state.query = "";
            return;
          }
          if (action.payload.id === ct.id) {
            ct.check = !action.payload.check
          }
          if (ct.check && state.query.indexOf(ct.id) === -1) {
            state.query += query + "&";
          }
          if (!ct.check && state.query.indexOf(ct.id) !== -1) {
            const split: any = state.query.split("&");
            state.query = split
              .map((el: any) => {
                if (el !== "" && el !== query) {
                  return el + "&";
                }
              })
              .join("");
          }
        });
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      state.status = EStatus.success;
    });
    builder.addCase(getFilters.rejected, (state, action) => {
      state.status = EStatus.error;
    });
  },
});

export const filtersActions = filtersSlice.actions;

export default filtersSlice.reducer;
