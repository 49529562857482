import styled from "styled-components";
import {baseThemeStyled} from "../../assets/styles/BaseTheme.styled";

export const Policy = styled.div.attrs({
  "data-id": "policy"
})`
  position: relative;
  h3{
    font: 600 21px/32px ${baseThemeStyled.fontsFamily.Lato};
    margin-bottom: 16px;
    @media ${baseThemeStyled.breakpoints.small} {
      margin-bottom: 8px;
    }
  }
  p{
    font: 400 15px/21px ${baseThemeStyled.fontsFamily.Lato};
    @media ${baseThemeStyled.breakpoints.small} {
      font: 400 14px/18px ${baseThemeStyled.fontsFamily.Lato};
    }
  }

`