import React from 'react';
import {
  ContainerTeam,
  Team,
  TeamImage,
  TeamInfo, TeamLi,
  TeamText,
  TeamTitle,
  TeamUl
} from "./AdvantagesPage.styled";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import {Container, TitleArea, Title, Page} from 'assets/styles/Main.styled';
import {IMAGES} from "../../constants/Images";
import HelmetComponent from "../../component/Helmet/Helmet";


const AdvantagesPage = () => {
  return (
    <Page bgColor="#F4F4F4">
      <HelmetComponent
          title="Преимущества"
          content="Преимущества"
      />
      <Breadcrumbs/>
      <Container>
        <TitleArea>
          <Title>
            Преимущества
          </Title>
        </TitleArea>
      </Container>
      <ContainerTeam>
        <Team>
          <TeamImage>
            <img src={IMAGES.Image1} alt=""/>
          </TeamImage>
          <TeamInfo>
            <TeamTitle>
              <h2>
                Обучение и развитие
              </h2>
            </TeamTitle>
            <TeamText>
              <span>В МТК Росберг</span> мы не только помогаем новым сотрудникам адаптироваться к работе,
              но и систематически проводим тренинги и вебинары как с ведущими специалистами компании, так и с
              приглашенными спикерами.
            </TeamText>
          </TeamInfo>
        </Team>

        <Team revers={true}>
          <TeamImage>
            <img src={IMAGES.Image2} alt=""/>
          </TeamImage>
          <TeamInfo>
            <TeamTitle>
              <h2>
                Современный офис
              </h2>
            </TeamTitle>
            <TeamText>
              <span>В 2020 году</span> состоялось торжественное открытие нового здания MTK Росберг. В отделке интерьера
              использовались современные дизайнерские решения, основанные на применении натуральных отделочных
              материалов.
            </TeamText>
          </TeamInfo>
        </Team>

        <Team>
          <TeamImage>
            <img src={IMAGES.Image3} alt=""/>
          </TeamImage>
          <TeamInfo>
            <TeamTitle>
              <h2>
                Премии сотрудникам
              </h2>
            </TeamTitle>
            <TeamUl>
              <TeamLi>
                Профессиональный, карьерный и финансовый рост;
              </TeamLi>
              <TeamLi>
                Премирование лучших сотрудников ежеквартально;
              </TeamLi>
              <TeamLi>
                Инициативные сотрудники получают бонусы.
              </TeamLi>
            </TeamUl>
          </TeamInfo>
        </Team>

        <Team revers={true}>
          <TeamImage>
            <img src={IMAGES.Image4} alt=""/>
          </TeamImage>
          <TeamInfo>
            <TeamTitle>
              <h2>
                Компенсация затрат
              </h2>
            </TeamTitle>
            <TeamText>
              Для вас как для сотрудника копании предусмотрен ряд компенсационных выплат, а именно:
            </TeamText>
            <TeamUl>
              <TeamLi>
                компенсация транспортных расходов на использование личного транспорта;
              </TeamLi>
              <TeamLi>
                предоставление корпоративной мобильной связи.
              </TeamLi>
            </TeamUl>
          </TeamInfo>
        </Team>

        <Team>
          <TeamImage>
            <img src={IMAGES.Image5} alt=""/>
          </TeamImage>
          <TeamInfo>
            <TeamTitle>
              <h2>
                Бонусы от компании
              </h2>
            </TeamTitle>
            <TeamText>
              Корпоративное предложение для сотрудников:
            </TeamText>
            <TeamUl>
              <TeamLi>
                корпоративные скидки в фитнес-клуб;
              </TeamLi>
              <TeamLi>
                спокойная рабочая атмосфера;
              </TeamLi>
              <TeamLi>
                скидки на продукцию.
              </TeamLi>
            </TeamUl>
          </TeamInfo>
        </Team>

        <Team revers={true}>
          <TeamImage>
            <img src={IMAGES.Image6} alt=""/>
          </TeamImage>
          <TeamInfo>
            <TeamTitle>
              <h2>
                Молодой коллектив
              </h2>
            </TeamTitle>
            <TeamText>
              В МТК Росберг мы не только <span>помогаем</span> новым сотрудникам адаптироваться
              к работе, но и систематически проводим тренинги и вебинары как с ведущими специалистами компании, так и с
              приглашенными спикерами.
            </TeamText>
          </TeamInfo>
        </Team>

        <Team>
          <TeamImage>
            <img src={IMAGES.Image7} alt=""/>
          </TeamImage>
          <TeamInfo>
            <TeamTitle>
              <h2>
                Корпоративные мероприятия
              </h2>
            </TeamTitle>
            <TeamText>
              Вы станете участником корпоративных мероприятий:
            </TeamText>
            <TeamUl>
              <TeamLi>
                спортивные и культурные события;
              </TeamLi>
              <TeamLi>
                праздники компании;
              </TeamLi>
              <TeamLi>
                конкурсы и многие другие.
              </TeamLi>
            </TeamUl>
          </TeamInfo>
        </Team>

        <Team revers={true}>
          <TeamImage>
            <img src={IMAGES.Image8} alt=""/>
          </TeamImage>
          <TeamInfo>
            <TeamTitle>
              <h2>
                Самореализация
              </h2>
            </TeamTitle>
            <TeamText>
              Сотрудники участвуют в разработке и реализации <span>крупных проектов</span> компании и, также, активно
              вносят
              предложения по улучшениям корпоративной жизни, взаимодействию с партнерами и клиентами.
            </TeamText>
          </TeamInfo>
        </Team>

        <Team>
          <TeamImage>
            <img src={IMAGES.Image9} alt=""/>
          </TeamImage>
          <TeamInfo>
            <TeamTitle>
              <h2>
                Выставки и форумы
              </h2>
            </TeamTitle>
            <TeamText>
              <span>Посещение выставок</span> и тематических форумов помогает нашим сотрудникам не только найти новые каналы сбыта
              продукции, но и завести новые знакомства, расширить кругозор и повысить свои профессиональные навыки.
            </TeamText>
          </TeamInfo>
        </Team>

      </ContainerTeam>
    </Page>
  );
};

export default AdvantagesPage;
