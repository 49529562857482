import styled, {css} from "styled-components/macro";
import {Link} from "react-router-dom";
import {baseThemeStyled} from "../../assets/styles/BaseTheme.styled";
import {IMAGES} from "../../constants/Images";

export const BreadcrumbsEl = styled.div.attrs({
  "data-id": "breadcrumbs"
})`
  position: relative;
  margin-bottom: 13px;
  @media ${baseThemeStyled.breakpoints.medium}{
    margin-bottom: 16px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    margin-bottom: 5px;
  }
`

export const BreadcrumbsLinks = styled.div.attrs({
  "data-id": "breadcrumbs-links"
})`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const BreadcrumbsText = css`
  color: #38454E;
  font: 400 14px/17px ${baseThemeStyled.fontsFamily.Lato};
  position: relative;
  margin-bottom: 5px;
  text-decoration-line: none;
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    font: 400 13px/16px ${baseThemeStyled.fontsFamily.Lato};
  }
`

export const BreadcrumbsLink = styled(Link).attrs({
  "data-id": "breadcrumbs-link"
})`
  ${BreadcrumbsText};
  margin-right: 20px;
  &:before{
    background-image: url(${IMAGES.Icon11});
    content: "";
    position: absolute;
    top: 6px;
    right: -12px;
    width: 4px;
    height: 6px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall}{
    margin-right: 20px;
    &:before{
      right: -13px;
    }
  }
`

export const BreadcrumbsSpan = styled.span.attrs({
  "data-id": "breadcrumbs-span"
})`
  ${BreadcrumbsText};
  color: #2F5379;
`