/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "assets/styles/Main.styled";
import { memo, useMemo } from "react";
import {
  BreadcrumbsEl,
  BreadcrumbsLink,
  BreadcrumbsLinks,
  BreadcrumbsSpan,
} from "./Breadcrumbs.styled";
import { useLocation } from "react-router-dom";
import { ILink, links } from "../Header/header_helper";

const Breadcrumbs = () => {
  const { pathname } = useLocation();

  const createBreacrumbs = useMemo(() => {
    let routes: ILink[] = [];
    let routesList = pathname.split("/");
    links.forEach((link, index) => {
      let route = link.route.slice(1);
      if (routesList.includes(route)) {
        routes.push(link);
      }
    });
    return routes;
  }, [pathname]);

  const breacrumbsMemo = useMemo(() => {
    return createBreacrumbs.map((link) => {
      return <BreadcrumbsSpan key={link.title}>{link.title}</BreadcrumbsSpan>;
    });
  }, [pathname]);

  return (
    <Container>
      <BreadcrumbsEl>
        <BreadcrumbsLinks>
          <BreadcrumbsLink to="/">Главная</BreadcrumbsLink>
          {breacrumbsMemo}
        </BreadcrumbsLinks>
      </BreadcrumbsEl>
    </Container>
  );
};

export default memo(Breadcrumbs);
