import {createApi} from '@reduxjs/toolkit/query/react';
import {fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';
import {baseUrl} from '../utils/Constants';

/*
  *   build.query<То Что получаем, То что передаем в запрос>
  *   если void то в запросе не сможем использовать options
  *   если any то можем
  */

export const GlobalApi = createApi({
    tagTypes: ['Vacancies'],  //какие теги сушествуют
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    }),
    endpoints: build => ({})
  })