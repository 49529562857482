import Filter from "../../components/filter/Filter";
import {
  FilterOverlay,
  MobileFilterArea,
  MobileFilterComponentEl,
} from "./MobileFilter.styled";

const MobileFilterComponent = () => {
  return (
    <MobileFilterComponentEl>
      <FilterOverlay />
      <MobileFilterArea>
        <Filter />
      </MobileFilterArea>
    </MobileFilterComponentEl>
  );
};

export default MobileFilterComponent;
