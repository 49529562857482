import styled from "styled-components";
import {Button, Container} from "../../assets/styles/Main.styled";
import {baseThemeStyled} from "../../assets/styles/BaseTheme.styled";
import {css} from "styled-components/macro";
import {Link} from "react-router-dom";

export const FooterTop = styled.div.attrs({
  'data-id': 'footer-top'
})`
  background: #273D54;
  position: relative;
  z-index: 5;
`

const FooterGrid = css`
  padding-top: 35px;
  padding-bottom: 35px;
`
const Grid = css`
  display: grid;
  grid-template-rows: repeat(5, auto);
  grid-template-columns: 1.5fr 1.3fr 1fr 1.2fr;
  gap: 16px;
  align-items: start;
  justify-items: start;
  @media ${baseThemeStyled.breakpoints.large} {
    grid-template-columns: 1.5fr 1fr 1fr 1.2fr;
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    grid-template-columns: 1.75fr 0fr 1fr 1.2fr;
  }
  @media ${baseThemeStyled.breakpoints.small} {
    grid-template-columns: 1fr 0fr 0fr 1fr;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    gap: 10px;
    grid-template-columns: 1fr;
    justify-items: center;
  }
`
export const ContainerGrid = styled(Container).attrs({
  'data-id': 'container-grid'
})`
  ${FooterGrid};
  ${Grid}
`

export const GridLogo = styled.div.attrs({
  'data-id': 'grid-logo'
})`
  grid-row: 1/2;
  grid-column: 1/2;
  @media ${baseThemeStyled.breakpoints.small} {
    grid-row: 2/3;
    grid-column: 1/2;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-row: 2/3;
    grid-column: 1/2;
  }
`

export const FooterLogoWrapper = styled(GridLogo).attrs({
  'data-id': 'footer-logo-wrapper'
})`
  padding-bottom: 15px;
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    padding-bottom: 5px;
  }
`

export const GridText = styled.div.attrs({
  'data-id': 'grid-text'
})`
  grid-row: 2/4;
  grid-column: 1/2;
  @media ${baseThemeStyled.breakpoints.small} {
    grid-row: 3/5;
    grid-column: 1/2;
  }
`

export const FooterText = styled.p.attrs({
  'data-id': 'footer-text'
})`
  font: 600 14px/130% ${baseThemeStyled.fontsFamily.Lato};
  color: #fff;
  @media ${baseThemeStyled.breakpoints.medium} {
    font: 600 13px/130% ${baseThemeStyled.fontsFamily.Lato};
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    display: none;
  }
`
export const GridButton = styled.div.attrs({
  'data-id': 'grid-button'
})`
  grid-row: 4/5;
  grid-column: 1/2;
  @media ${baseThemeStyled.breakpoints.small} {
    grid-row: 5/6;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-row: 6/7;
    grid-column: 1/2;
  }
`

export const FooterButton = styled(Button).attrs({
  'data-id': 'footer-button'
})`
  border: 1px solid #FFFFFF;
  border-radius: 1px;
  font: 700 13px/16px ${baseThemeStyled.fontsFamily.Lato};
  color: #fff;
  text-transform: uppercase;
  padding: 12px 22px;
  background: transparent;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;

  &:hover, &:active, &:focus {
    color: #273D54;
    background: #FFFFFF;
  }

  @media ${baseThemeStyled.breakpoints.extraSmall} {
    padding: 7px 27px;
    font: 700 12px/16px ${baseThemeStyled.fontsFamily.Lato};
  }
`

export const GridLink = styled.div.attrs({
  'data-id': 'footer-button'
})`
  grid-row: 5/6;
  grid-column: 1/2;
  @media ${baseThemeStyled.breakpoints.small} {
    grid-row: 6/7;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-row: 3/4;
    grid-column: 1/2;
  }
`

export const FooterLink = styled(Link).attrs({
  'data-id': 'footer-link'
})`
  font: 400 14px/28px ${baseThemeStyled.fontsFamily.Lato};
  text-decoration-line: underline;
  color: #fff;
  transition: font-weight 0.3s ease-in-out;

  &:hover, &:active, &:focus {
    font-weight: 700;
    color: #fff;
  }

  @media ${baseThemeStyled.breakpoints.small} {
    font: 400 13px/28px ${baseThemeStyled.fontsFamily.Lato};
  }
`

const GridNav = css`
  grid-row: 1/6;
  grid-column: 3/4;
  justify-self: center;
  @media ${baseThemeStyled.breakpoints.small} {
    grid-row: 1/2;
    grid-column: 1/5;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-column: 1/2;

  }
`

export const FooterNav = styled.nav.attrs({
  'data-id': 'footer-nav'
})`
  ${GridNav};
  @media ${baseThemeStyled.breakpoints.small} {
    width: 100%;
    overflow: auto;
  }
`

export const FooterNavList = styled.ul.attrs({
  'data-id': 'footer-nav-list'
})`
  padding-top: 11px;
  @media ${baseThemeStyled.breakpoints.small} {
    max-width: 735px;
    display: flex;
    gap: 35px;
    padding-top: 0;
    justify-content: space-between;
  }
`

export const FooterNavItem = styled.li.attrs({
  'data-id': 'footer-nav-item'
})`
  padding-bottom: 18px;

  &:last-child {
    padding-bottom: 0;
  }
`

export const FooterNavLink = styled(Link).attrs({
  'data-id': 'footer-nav-link'
})`
  white-space: nowrap;
  font: 400 16px/19px ${baseThemeStyled.fontsFamily.Lato};
  color: #fff;
  position: relative;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease-in-out;

  &:hover, &:active, &:focus {
    text-decoration-color: #fff;
    color: #fff;
  }

  &:before {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background: #fff;
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
    @media ${baseThemeStyled.breakpoints.small} {
      display: none;
    }
  }
`

export const FooterBottom = styled.div.attrs({
  'data-id': 'footer-bottom'
})`
  background: #fff;
`

const GridBottom = css`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
`

export const FooterBottomWrapper = styled.div.attrs({
  'data-id': 'footer-bottom-wrapper'
})`
  ${GridBottom};
  padding: 15px 0;
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    padding: 10px;
  }
`

export const FooterBottomText = styled.p.attrs({
  'data-id': 'footer-bottom-text'
})`
  font: 400 13px/150% ${baseThemeStyled.fontsFamily.Lato};
  color: #626060;
  @media ${baseThemeStyled.breakpoints.small} {
    font: 400 12px/16px ${baseThemeStyled.fontsFamily.Lato};
  }
`

export const GridContact = styled.div.attrs({
  'data-id': 'grid-contact'
})`
  grid-row: 1/6;
  grid-column: 4/5;
  @media ${baseThemeStyled.breakpoints.small} {
    justify-self: end;
    grid-row: 2/7;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    grid-row: 4/6;
    grid-column: 1/2;
    padding-bottom: 12px;
    justify-self: center;
  }
`

export const GridContactTitle = styled.p.attrs({
  'data-id': 'grid-contact-main-title'
})`
  font: 800 22px/30px ${baseThemeStyled.fontsFamily.Lato};
  color: #fff;
  padding-bottom: 15px;
  @media ${baseThemeStyled.breakpoints.medium} {
    padding-bottom: 20px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    display: none;
  }
`

export const FooterContactsList = styled.ul.attrs({
  'data-id': 'footer-contacts-list'
})`
  position: relative;
`

export const FooterContactsItem = styled.li.attrs({
  'data-id': 'footer-contacts-item'
})`
  padding-bottom: 20px;

  &:last-child {
    padding-bottom: 0;
  }

  @media ${baseThemeStyled.breakpoints.extraSmall} {
    .footer__contacts-item {
      padding-bottom: 9px;
    }
  }
`

export const FooterContactsLink = styled(Link).attrs({
  'data-id': 'footer-contacts-link'
})<{ type: string }>`
  color: #fff;
  position: relative;
  padding-left: 27px;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease-in-out;
  ${props => {
    if (props.type === "tel") {
      return css`
        font: 700 15px/19px ${baseThemeStyled.fontsFamily.Lato};
        @media ${baseThemeStyled.breakpoints.medium} {
          font-size: 14px;
        }
      `
    }
  }};

  &:hover, &:active, &:focus {
    text-decoration-color: #fff;
    color: #fff;
  }
`

export const FooterContactsIcon = styled.div.attrs({
  'data-id': 'footer-contacts-icon'
})`
  position: absolute;
  left: 0;
  top: 55%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
`