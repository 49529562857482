/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./filter.sass";
import { useTypedSelector } from "../../../../hooks/useTypedSelection";
import { useActions } from "../../../../hooks/useActions";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { getAllVacancies } from "../../../../redux/Vacancies/VacanciesSlice";
import { IFilter, IFilterItem } from "../../../../redux/Filters/FiltersSlice";
import api from "../../../../api/api";
import { CategoryType } from "../../../../utils/Constants";
import { Declination } from "../../../../utils/Helpers";
import { IconSvg } from "../../../../utils/shared";
import { CloseFilterButton } from "../mobile-filter/MobileFilter.styled";

const Filter = () => {
  const { filters, status, query } = useTypedSelector((state) => state.filters);
  const { vacancies, pagination } = useTypedSelector(
    (state) => state.vacancies
  );

  const { checkCategory, changeMobileFilter } = useActions();

  const [positionY, setPositionY] = useState<number | null>(null);
  const [filterBlock, setFilterBlock] = useState([]);
  //количество вакансий для выпадашки в блоке фильтр
  const [countVacancies, setCountVacancies] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const wrapperRef = useRef<HTMLImageElement | null>(null);

  useOutsideAlerter(wrapperRef);

  const showVacancyFilter = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setPositionY(null);
    changeMobileFilter(false);
    dispatch(getAllVacancies(query));
    navigate(`${location.pathname}?${query}`);
  };

  const deleteAllTags = () => {
    checkCategory(null);
    setPositionY(null);
    //если количество вакансий не менялось фильтром
    //при сбросе фильтров не запрашиваем вакансии
    if (pagination.SELECTED_COUNT !== vacancies.length) {
      getAllVacancies(query);
    }
  };

  const openCloseFilterBlock = (key: string) => {
    setPositionY(0);
    let x = { ...filterBlock };
    if (key in x) {
      delete x[key];
    } else {
      x[key] = true;
    }
    setFilterBlock(x);
  };

  const handleCoordinateMouse = (e: React.FormEvent, filter: IFilterItem) => {
    e.preventDefault();
    e.stopPropagation();
    checkCategory(filter);
    //@ts-ignore
    setPositionY(e.nativeEvent.layerY - 14);
  };

  useEffect(() => {
    const init = async () => {
      await api.getAllVacancies(query).then((res) => {
        setCountVacancies(res.data.SELECTED_COUNT);
      });
    };
    init();
  }, [query]);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        // event.preventDefault()
        // event.stopPropagation()
        const parent: any = document.querySelector("#filter-drop-block");
        if (parent.contains(event.target)) return;
        if (ref.current && !ref.current.contains(event.target)) {
          setPositionY(null);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const refVacancies = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (window.matchMedia("(max-width: 992px)").matches) {
      if (!refVacancies.current) return;
      document.body.scrollTo({
        top: refVacancies.current.offsetTop * 1.5,
        behavior: "smooth",
      });
      // refVacancies.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [vacancies]);

  return (
    <div className="filter-area">
      <div
        style={{ top: `${positionY}px` }}
        id="filter-drop-block"
        className={"filter-drop-block " + (positionY ? "" : "d-none")}
      >
        <div
          onClick={(e) => showVacancyFilter(e)}
          className="filter-drop-block__result"
        >
          Найдены {countVacancies}&nbsp;
          {Declination(countVacancies, ["вакансия", "вакансии", "вакансий"])}
        </div>
      </div>

      <div className="filter">
        <div className="filter__header">
          Фильтры
          <CloseFilterButton onClick={() => changeMobileFilter(false)}>
            {IconSvg.ui.close()}
          </CloseFilterButton>
        </div>
        <div className="filter__body">
          <button
            onClick={() => deleteAllTags()}
            className="filter__clean-button"
          >
            <span>Очистить все</span>
          </button>

          {filters.map((item: IFilter, index: number) => {
            return (
              <div className="filter-item" key={index}>
                <div className="filter-item__title">
                  {CategoryType[item.type]}
                </div>
                {item?.items?.map((filter: IFilterItem, ind: number) => {
                  return (
                    <div key={ind} className="filter-item__row">
                      <label
                        onClick={(e) => handleCoordinateMouse(e, filter)}
                        className="checkbox"
                      >
                        <input
                          type="checkbox"
                          checked={!!filter.check}
                          readOnly
                        />
                        <div className="checkbox-cub" />
                        <div className="checkbox-content">
                          <span className="filter-item__row-text">
                            {" "}
                            {filter.value}
                          </span>
                          <span className="filter-item__row-count">
                            {filter.count}
                          </span>
                        </div>
                      </label>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {status === "success" && (
        <>
          {/*<div ref={wrapperRef}>{blockCategory()}</div>*/}
          <div style={{ opacity: 0, height: "1px" }} ref={refVacancies} />
          <button
            onClick={(e) => showVacancyFilter(e)}
            className="filter-show-button"
          >
            Показать вакансии
          </button>
        </>
      )}
      {status === "error" && (
        <div className="form__error form__error-visible">
          Не удалось загрузить фильтры
        </div>
      )}
    </div>
  );
};

export default Filter;
