/* eslint-disable array-callback-return */
import React from "react";
import { MainTagButton, MainTagButtonArea, MainTagEl } from "./MainTag.styled";
import { useTypedSelector } from "../../../../hooks/useTypedSelection";
import { IFilter, IFiltersState } from "../../../../redux/Filters/FiltersSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const MainTag = () => {
  const { filters, status }: IFiltersState = useTypedSelector(
    (state) => state.filters
  );

  return (
    <MainTagEl>
      {status === "idle" && (
        <MainTagButtonArea>
          <SkeletonTheme baseColor="#DBDBDB" highlightColor="#ecebeb">
            <Skeleton style={{ height: "50px" }} count={1} />
          </SkeletonTheme>
        </MainTagButtonArea>
      )}
      {status === "success" && (
        <MainTagButtonArea>
          {filters?.map((item: IFilter, index: number) => {
            if (item.type === "SECTION") {
              return item.items.slice(0, 4).map((it, ind) => {
                return (
                  <MainTagButton
                    key={ind}
                    to={`/vacancies?SECTION_ID[]=${it.id}`}
                  >
                    {it.value}
                  </MainTagButton>
                );
              });
            }
          })}
        </MainTagButtonArea>
      )}
    </MainTagEl>
  );
};

export default MainTag;
