/* eslint-disable no-unused-vars */
import  { useContext, useEffect, useRef, useState} from 'react';
import InputMask from 'react-input-mask'
import MainTitle from "../main-title/MainTitle";
import {
  MainSectionForm, MainSectionFormImage,
  SubTitle,
} from "./MainForm.styled";
import {Months, Years} from "../../../../utils/Constants";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import Select, {components} from "react-select";
import Loading from "../../../../component/Loading/Loading";
import {InterviewContext} from "../../../../context/interview";
import api from "../../../../api/api";
import {NormalizeVacancyView} from "../../../../utils/Helpers";
import "./style.sass"
import "../../../../assets/styles/react-date-picker.css"
import DatePicker, {registerLocale} from "react-datepicker"
import ru from "date-fns/locale/ru";
import {getYear} from "date-fns";
import {IMAGES} from "../../../../constants/Images";
import {
  Section, Container,
  Checkbox,
  CheckboxInput,
  CheckboxLabel,
  CheckboxSquare,
  CheckboxText,
  CheckboxLink
} from "../../../../assets/styles/Main.styled";

registerLocale("ru", ru);

const CustomOption = ({children, ...props}) => {
  return (
    <components.Option {...props} className="custom-option">
      <div className="custom-option__border">
        <div className="custom-option__item">
          {children}
        </div>
      </div>
    </components.Option>
  );
};

const CustomMenuList = ({children, ...props}) => {
  return (
    <components.MenuList {...props} className="custom-menu-list">
      {children}
    </components.MenuList>
  );
};
const CustomValueContainer = ({children, ...props}) => {
  return (
    <components.MenuList {...props} className="custom-value-container">
      {children}
    </components.MenuList>
  );
};

const CustomDropdownIndicator = props => {
  return (
    <components.DropdownIndicator className="select-icon" {...props}>
      <img
        src={IMAGES.SelectIcon}
        alt=""/>
      {/*<CaretDownIcon />*/}
    </components.DropdownIndicator>
  );
};

const MainForm = ({type = ""}) => {

  const selectRef = useRef();
  const [post, setPost] = useState()
  const [loadingPosition, setLoadingPosition] = useState(true)

  const [fileError, setFileError] = useState('')

  const {
    showCommentInput, setShowCommentInput,
    fio, setFio,
    phone, setPhone,
    birthday, setBirthday,
    email, setEmail,
    position, setPosition,
    file, setFile,
    comment, setComment,
    agreement, setAgreement,
    success, setSuccess,
    loadingSuccess, setLoadingSuccess,
    errorPhone, setErrorPhone,
    errorFio, setErrorFio,
    errorBirthday, setErrorBirthday,
    city, setCity,
    errorCity, setErrorCity,
    resetFormInterview,
    setModalSuccess,
    setFormModal
  } = useContext(InterviewContext)

  const handleSubmit = (e) => {

    e.preventDefault()
    e.stopPropagation()
    if (!agreement) return
    setLoadingSuccess(true)
    const formData = new FormData()
    formData.append('PHONE', phone)
    formData.append('FIO', fio)
    formData.append('BIRTHDAY', birthday)
    formData.append('EMAIL', email)
    formData.append('POSITION', position)
    formData.append('FILE', file)
    formData.append('COMMENT', comment)
    formData.append('ACTION', 'INTERVIEW')
    formData.append('CITY', city)
    // console.log('formData', formData)
    api.getInterview(formData).then(res => {
      setSuccess(true)
      setModalSuccess(true)
      setLoadingSuccess(false)
      resetFormInterview()
      setFormModal(false)
      selectRef.current.setValue("")
    }).catch(e => {
      // console.log('e', e)
      setErrorPhone(e.response.data.ERROR.phone)
      setErrorFio(e.response.data.ERROR.fio)
      setErrorBirthday(e.response.data.ERROR.birthday)
      setErrorCity(e.response.data.ERROR.city)
      setLoadingSuccess(false)
    })
  }

  useEffect(() => {
    // setErrorPhone('')
    // setErrorFio('')
    setLoadingPosition(true)
    api.getAllVacancies().then(resp => {
      let newArraySection = []
      NormalizeVacancyView(resp.data.ITEMS)
      NormalizeVacancyView(resp.data.ITEMS).forEach(item => {
        newArraySection.push(
          {
            value: item.NAME,
            label: item.NAME,
          }
        )
      })
      setPost(newArraySection)
    }).catch(error => {
      // console.log('resp', error)
    })

  }, [])

  useEffect(() => {
    if (!post) return
    setLoadingPosition(false)
  }, [post])

  const handleCheckAgreement = (e, agr) => {
    e.preventDefault()
    e.stopPropagation()
    setAgreement(!agr)
  }

  const onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 5242880) {
        setFileError('Максимальный размер файла 5 мегабайт')
      } else {
        // console.log(event.target.files[0])
        setFile(event.target.files[0])
      }
    }
  }

  const colourStyles = {
    // dropdownIndicator: (base, state) => ({
    //   // backgroundColor: 'blue'
    // }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#d7d8d9" : "#fff",
      ':active': {
        backgroundColor: state.isSelected ? "#B6BABD" : "#B6BABD"
      }
    }),
    singleValue: (...base) => ({
      color: "#000",
      position: "relative",
      top: "14px",
      marginLeft: 0,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: "100%"
    }),
    control: (base, state) => ({
      ...base,
      height: "30px",
      minHeight: "30px",
      display: "flex",
      flexWrap: "no-wrap",
      maxHeight: "30px",
      paddingLeft: (type === 'form-modal' ? '10px' : '0'),
      border: "0 !important",
      boxShadow: "0 !important",
      color: "#000",
      backgroundColor: 'transparent',
      "&:hover": {
        border: "0 !important"
      }
    })
  }

  // console.log(position)

  return (
    <MainSectionForm bgColor={"#fff"}>
      <Container>
        <div className="kto_prochital_tot_chereshnya">
          <MainTitle
            mb={0}
            lr_mb={0}
            md_mb={0}
            sm_mb={0}
            esm_mb={0}
            title="Заполните анкету"
            link_title=""
            link_route="/"
          />
          <SubTitle>
            И мы рассмотрим ее в ближайшее время!
          </SubTitle>
        </div>
        <div className="form-main-area">
          <form className={"form-main form " + (type)} onSubmit={handleSubmit}>
            <div className="form__text-red">
              <span className="text-red">Оставьте</span> свою почту, чтобы получить рекомендации для подготовки к
              собеседованию
            </div>
            <div className="form__group">

              <div className="form-col form__input-container">
                <div className={"form__field " + (fio ? 'form__no-empty' : '')}>
                  <input type="text" id={"fullname-" + type}
                         className={"form__input " + (errorFio ? 'form__input-error' : '')}
                         placeholder={type === 'form-modal' ? "ФИО" : ''}
                         value={fio || ''}
                         onChange={(e) => setFio(e.target.value)}
                  />
                  <label htmlFor={"fullname-" + type} className="form__label">ФИО<span>*</span></label>
                </div>
                <div className={"form__error " + (errorFio ? 'form__error-visible' : '')}>{errorFio}</div>
              </div>

              <div className="form-col form__input-container">
                <div className={"form__field " + (birthday ? 'form__no-empty' : '')}>

                  <DatePicker
                    locale="ru"
                    className={"form__input w-100 custom-date-picker " + (errorBirthday ? 'form__input-error' : '')}
                    selected={birthday}
                    onChange={(date) => setBirthday(date)}
                    dateFormat="dd.MM.yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    placeholderText={type === 'form-modal' ? "01.01.1970" : ''}
                    dropdownMode="select"
                    renderCustomHeader={({
                                           date,
                                           changeMonth,
                                           changeYear,
                                         }) => (
                      <div>
                        <select
                          value={getYear(date)}
                          onChange={({target: {value}}) => changeYear(value)}
                        >
                          {Years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <select
                          // value={Months[getMonth(date)]}
                          onChange={({target: {value}}) =>
                            changeMonth(Months.indexOf(value))
                          }
                        >
                          {Months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>

                    )}
                  />
                  <label htmlFor={"date-" + type} className="form__label">Дата рождения<span>*</span></label>
                </div>
                <div className={"form__error " + (errorBirthday ? 'form__error-visible' : '')}>{errorBirthday}</div>
              </div>

              <div className="form-col form__input-container">
                <div className={"form__field " + (city ? 'form__no-empty' : '')}>
                  <input type="text" id={"fullname-" + type}
                         className={"form__input " + (errorFio ? 'form__input-error' : '')}
                         placeholder={type === 'form-modal' ? "Город" : ''}
                         value={city || ''}
                         onChange={(e) => setCity(e.target.value)}
                  />
                  <label htmlFor={"fullname-" + type} className="form__label">Город<span>*</span></label>
                </div>
                <div className={"form__error " + (errorCity ? 'form__error-visible' : '')}>{errorCity}</div>
              </div>

              <div className="form-col form__input-container ">
                <div className={"form__field " + (email ? 'form__no-empty' : '')}>
                  <input type="text"
                         id={"email-" + type}
                         value={email || ''}
                         placeholder={type === 'form-modal' ? "mymail@mail.ru" : ''}
                         onChange={(e) => setEmail(e.target.value)}
                    // className="form__input form__input-error"
                         className="form__input"
                  />
                  <label htmlFor={"email-" + type} className="form__label">Email</label>
                </div>
              </div>

              <div className="form-col form__input-container">
                <div className={"form__field " + (phone ? 'form__no-empty' : '')}>
                  <InputMask
                    id={"phone-" + type}
                    // required={true}
                    placeholder={type === 'form-modal' ? "+7(" : ''}
                    className={"form__input " + (errorPhone ? 'form__input-error' : '')}
                    value={phone || ''}
                    onChange={(e) => setPhone(e.target.value)}
                    mask="+7(999) 999-99-99"
                    maskChar={null}
                  />

                  <label htmlFor={"phone" + type} className="form__label">Номер телефона<span>*</span></label>

                </div>
                <div className={"form__error " + (errorPhone ? 'form__error-visible' : '')}>{errorPhone}</div>
              </div>

              <div className="form-col form__input-container form__input-container-error ">

                {loadingPosition
                  ?
                  <SkeletonTheme baseColor="#f3f3f3" highlightColor="#ecebeb">
                    <Skeleton style={{height: '30px'}} count={1}/>
                  </SkeletonTheme>
                  :
                  <div className={"form__field " + (position ? 'form__no-empty' : '')}>

                    <div className="position-relative" style={{zIndex: '2'}}>
                      <Select
                        ref={selectRef}
                        // menuIsOpen={true}
                        styles={colourStyles}
                        id={"post-" + type}
                        defaultValue={position !== null ? position : ""}
                        placeholder={type === 'form-modal' ?
                          <div className="select-placeholder">Выберите должность</div> : ''}
                        onChange={e => setPosition(e.value)}
                        className="form__input simple-select"
                        options={post}
                        components={{
                          Option: CustomOption,
                          MenuList: CustomMenuList,
                          ValueContainer: CustomValueContainer,
                          DropdownIndicator: CustomDropdownIndicator,
                          IndicatorSeparator: () => null
                        }}
                      />
                    </div>
                    <label htmlFor={"post-" + type}
                           className="form__label">Желаемая должность</label>
                  </div>
                }

              </div>

              <div className="form-col w">
                <div className="form__text-d checkbox-fade--mb-10">* Поля, обязательные для заполнения</div>

              </div>

              <div className="form-col vis"></div>

              <div className="form-col w">
                <div className="form__info">


                  <Checkbox>
                    <CheckboxLabel
                      onChange={() => setAgreement(!agreement)}>
                      <CheckboxInput checked={agreement} onChange={()=> {}}>

                      </CheckboxInput>
                      <CheckboxSquare>
                      </CheckboxSquare>

                      <CheckboxText>
                        Я согласен с <CheckboxLink to="/privacy">условиями передачи данных</CheckboxLink>
                      </CheckboxText>

                    </CheckboxLabel>
                  </Checkbox>

                </div>
              </div>

              <div className="form-col">
                {file && <div
                  title={file.name}
                  className="form-file"
                >{file.name}
                </div>
                }
              </div>

              <div className="form-col vis"></div>

              <div className="form-col"></div>

              <div className="form-col ">

                {!file
                  ?
                  <button className="button button__file mt-10 mb-10">
                    <input type="file" className="button__input" onChange={onFileChange}/>
                    Прикрепите файл (pdf, docx)
                  </button>
                  :
                  <button className="button button__file mt-10 mb-10" onClick={(e) => setFile('')}>
                    Удалить файл
                  </button>
                }

                {/*<div className="form__text-blue d-flex align-items-center flex-wrap flex-column">*/}
                {/*  <span className="mr-10">{file.name}</span>*/}
                {/*  <button className="button button__file mt-10 mb-10 ml-auto" onClick={(e) => setFile('')}>*/}
                {/*    Удалить файл*/}
                {/*  </button>*/}
                {/*</div>*/}
                {/*{fileError && <div className="mt-5 form__error form__error-visible">{fileError}</div>}*/}

              </div>

              <div className="form-col vis"></div>

              <div className="form-col">

                <div className="form__empty-block">

                </div>
                {!loadingSuccess
                  ?
                  <button
                    type="submit"
                    className={"button button__red mt-10 mb-10 form__button__red " + (!agreement ? 'button__red--no-click' : '')}>
                    Отправить анкету
                  </button>
                  :
                  <button
                    type="button"
                    className="button button__red  mt-10 mb-10 form__button__red button__red--no-click">
                    <div className="loading form__loading">
                      <Loading/>
                    </div>
                  </button>
                }


              </div>

            </div>

            {type === 'form-modal' && showCommentInput
              &&
              <div className="form__group d-flex align-items-baseline flex-wrap">
                <div className="form__input-container ">
                  <div className="form__field">
                    <input type="text"
                           id={"comment-" + type}
                           value={comment || ''}
                           onChange={(e) => setComment(e.target.value)}
                      // className="form__input form__input-error"
                           className="form__input"
                           placeholder="Комментарий"/>
                    <label htmlFor={"email-" + type} className="form__label">Комментарий</label>

                  </div>
                </div>
              </div>
            }
          </form>
          <MainSectionFormImage/>
        </div>
      </Container>
    </MainSectionForm>
  );
};


export default MainForm;

