import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "api/api";
import { NormalizePaginationView, NormalizeVacancyView } from "utils/Helpers";
import { EStatus, IResponceVacantions } from "utils/types";

export interface IVacancie {
    id: string;
    name: string;
    chart: {
      enum_id: string;
      value: string;
    };
    city: {
      value: string;
    };
    preview_text: string;
    pay_text: string;
  }

interface IPagination {
  COUNT: number;
  PAGE: number;
  SELECTED_COUNT: number;
}

export interface IVacanciesState {
  vacancies: IVacancie[];
  pagination: IPagination;
  status: EStatus;
}

const initialState: IVacanciesState = {
  vacancies: [],
  status: EStatus.idle,
  pagination: {} as IPagination,
};

export const getAllVacancies = createAsyncThunk(
  "vacancies/getAllVacancies",
  async (link?: string) => {
    const response: IResponceVacantions = await api.getAllVacancies(link);
    // console.log("slice", response);
    return response.data;
  }
);

export const vacanciesSlice = createSlice({
  name: "vacancies",
  initialState,
  reducers: {
    changeStatusVacante(state, action: PayloadAction<EStatus>){
        state.status = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVacancies.pending, (state, action) => {
      state.status = EStatus.idle;
    });
    builder.addCase(getAllVacancies.fulfilled, (state, action) => {
      state.vacancies = NormalizeVacancyView(action.payload.ITEMS);
      state.pagination = NormalizePaginationView(action.payload);
      state.status = EStatus.success;
    });
    builder.addCase(getAllVacancies.rejected, (state, action) => {
      state.status = EStatus.error;
    });
  },
});

export const vacanciesActions = vacanciesSlice.actions;

export default vacanciesSlice.reducer;
