/* eslint-disable array-callback-return */
//

export const price = (str) => {
  if (str === undefined || str === null) return 0
  return (parseInt(str.replace(/\s/g, ''), 10)).toLocaleString()
}

//Приводим в нормальный вид категории
export const NormalizeFilterView = (data, queryParams) => {
  //если есть параметры в ссылке то собираем массив из id параметров
  //при формировании нормального вида фильтров делаем активные поля фильтра
  let ids = []
  // console.log(queryParams)
  if(queryParams){
    Object.keys(queryParams).map(key=>{
      if(queryParams[key] !== undefined){
        ids.push(queryParams[key])
      }
    })
  }

  let flatIds = ids.flat()

  let arr = []
  for (let i = 0; i < Object.keys(data).length; i++) {
    let currentEl = Object.keys(data)[i]
    let obj = {}
    obj.type = currentEl
    obj.items = []
    Object.keys(data[currentEl]).forEach((key, ind) => {
      let closure = function () {
        let item = {}
        item.value = data[currentEl][key].VALUE
        item.id = +data[currentEl][key].ID ? +data[currentEl][key].ID : +data[currentEl][key].CODE
        item.count = +data[currentEl][key].COUNT
        item.check = false
        //этот if срабатывает если ксть параметры в ссылке
        if(flatIds.length > 0){
          for(let j = 0; j < flatIds.length; j++){
            if(item.id === +flatIds[j]){
              item.check = true
            }
          }
        }
        return item
      }
      obj.items.push(closure())
    })

    arr.push(obj)
  }
  return arr
}

//Приводим в нормальный вид вакансии
export const NormalizeVacancyView = (data) => {
  let arrayData = []
  Object.keys(data).forEach(item => {
    if(data[item].ACTIVE === 'Y'){
      arrayData.push(data[item])
    }
  })

  return arrayData.reverse()
}

//Приводим в нормальный вид пагинацию
export const NormalizePaginationView = (data) => {
  let pagination = {}
  pagination.COUNT = +data.COUNT
  pagination.PAGE = +data.PAGE
  pagination.SELECTED_COUNT = +data.SELECTED_COUNT
  return pagination
}

// склонение слов
export const Declination =(number, titles)=> {
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[ (number%100>4 && number%100<20)? 2:cases[(number%10<5)?number%10:5] ];
}
