
import {createSlice} from "@reduxjs/toolkit";

export interface IUIState {
    mobileFilter: boolean,
    isOpenMobileMenu: boolean
}

export const initialState: IUIState = {
    mobileFilter: false,
    isOpenMobileMenu: false
};

export const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        changeMobileFilter(state, action) {
            state.mobileFilter = action.payload
        },
        setIsOpenMobileMenu(state, action) {
            state.isOpenMobileMenu = action.payload
        }
    },
})
export const uiActions = uiSlice.actions;
export default uiSlice.reducer;
