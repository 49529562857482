import styled, {css} from "styled-components/macro";
import {baseThemeStyled} from "../../assets/styles/BaseTheme.styled"
import {Link} from "react-router-dom";
import {Button, Container} from "../../assets/styles/Main.styled";

export const HeaderEl = styled.section.attrs({
  "data-id": "header-el"
})`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
`

export const HeaderUpArea = styled.div.attrs({
  "data-id": "header-up-area"
})`
  background-color: ${baseThemeStyled.colors.blue};
  @media ${baseThemeStyled.breakpoints.large} {
    & > ${Container} {
      padding-left: 0;
    }
  }
  @media ${baseThemeStyled.breakpoints.small} {
    padding: 15px;
    & > ${Container} {
      padding-right: 0;
    }
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    padding: 12px;
  }
`

export const HeaderUpBody = styled.div.attrs({
  "data-id": "header-up-body"
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${baseThemeStyled.breakpoints.small} {
    justify-content: center;
  }
`

export const HeaderNavigation = styled.nav.attrs({
  "data-id": "header-navigation"
})`
  position: relative;
`

export const HeaderNavigationUl = styled.ul.attrs({
  "data-id": "header-navigation-ul"
})`
  display: flex;
  align-items: center;
  @media ${baseThemeStyled.breakpoints.small} {
    display: none;
  }
`

export const HeaderNavigationLi = styled.li.attrs({
  "data-id": "header-navigation-li"
})`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #486889;
  background-color: transparent;
  transition: 0.15s linear background-color;

  &:last-child {
    border-right: 1px solid #486889;
    @media ${baseThemeStyled.breakpoints.medium} {
      border-right: none;
    }
  }

  &:hover {
    background-color: #5E8EBB
  }
`

export const HeaderNavigationLink = styled(Link).attrs({
  "data-id": "header-navigation-link"
})`
  text-decoration-line: none;
  padding: 11px 27px 10px 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;

  img {
    margin-bottom: 5px;
  }

  span {
    color: ${baseThemeStyled.colors.body2};
    font: 700 12px/14px ${baseThemeStyled.fontsFamily.Lato};
    text-transform: uppercase;
  }

  @media ${baseThemeStyled.breakpoints.large} {
    ${HeaderNavigationLi}:first-child & {
      & {
        padding-left: 16px;
        border-left: none;
      }
    }
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    padding: 11px 15px 10px 15px;
  }
`

export const HeaderNavigationLinkIcon = styled.div.attrs({
  "data-id": "header-navigation-li-icon"
})`
  height: 25px;
  display: flex;
  justify-content: center;
`

export const HeaderDownArea = styled.div.attrs({
  "data-id": "header-down-area"
})`
  background-color: ${baseThemeStyled.colors.body2};
  //min-height: 60px;
`

export const ButtonInterview = styled(Button).attrs({
  "data-id": "button-interview"
})`
  background-color: ${baseThemeStyled.colors.body2};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: ${baseThemeStyled.fontsFamily.Lato};
  min-height: 30px;
  padding: 5px 20px;
  border-radius: 3px;
  white-space: nowrap;

  &:hover {
    background: rgba(239, 239, 239, 0.8);
    color: #3B4851;
  }

  @media ${baseThemeStyled.breakpoints.medium} {
    font-size: 12px;
    line-height: 15px;
    padding-left: 14px;
    padding-right: 14px;

  }
  @media ${baseThemeStyled.breakpoints.small} {
    font-size: 14px;
    line-height: 18px;
    padding-left: 20px;
    padding-right: 20px;
    min-height: auto;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    font-size: 12px;
    line-height: 15px;
    padding: 3px 13px;
  }
`

export const HeaderDown = styled.div.attrs({
  "data-id": "header-down"
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  @media ${baseThemeStyled.breakpoints.medium} {
    padding: 14px 0;
  }
  @media ${baseThemeStyled.breakpoints.small} {
    justify-content: flex-start;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    padding: 12px 0px;
  }
`

export const LogoLink = styled(Link).attrs({
  "data-id": "logo-link"
})`
  position: relative;
  display: inline-block;
  flex: 0 0 272px;
  svg{
    width: 100%;
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    flex: 0 0 253px;
  }
  @media ${baseThemeStyled.breakpoints.small} {
    flex: 0 0 253px;;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    flex: 0 0 190px;
  }
`

export const HeaderContact = styled.div.attrs({
  "data-id": "header-contact"
})`
  display: flex;
  position: relative;
  bottom: -4px;
  @media ${baseThemeStyled.breakpoints.medium} {
    bottom: -2px;
  }
  @media ${baseThemeStyled.breakpoints.small} {
    margin-left: auto;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    bottom: auto;
  }
`

const HeaderContactItemCss = css`
  margin-left: 50px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;

  & > span {
    margin-left: 9px;
  }

  span {
    font-size: 16px;
    line-height: 19px;
    @media ${baseThemeStyled.breakpoints.medium} {
      font-size: 14px;
      line-height: 17px;
    }
  }

  img {
    max-width: inherit;
  }

  @media ${baseThemeStyled.breakpoints.large} {
    margin-left: 30px;
  }
  @media ${baseThemeStyled.breakpoints.medium} {
    margin-left: 20px;
  }
  @media ${baseThemeStyled.breakpoints.small} {
    margin-left: 30px;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    margin-left: 15px;
  }
`

export const HeaderContactItem = styled.div.attrs({
  "data-id": "header-contact-item"
})`
  ${HeaderContactItemCss};
  @media ${baseThemeStyled.breakpoints.small} {
    &.header-contact-address {
      display: none;
    }
  }
`

export const HeaderContactLink = styled.a.attrs({
  "data-id": "header-contact-link"
})`
  ${HeaderContactItemCss};
  text-decoration-line: none;

  .text {
    font-weight: 700;
    color: ${baseThemeStyled.colors.body3};
    @media ${baseThemeStyled.breakpoints.extraSmall} {
      display: none;
    }
    @media ${baseThemeStyled.breakpoints.large} {
      //position: relative;
      //top: -1px;
    }
  }

  .text2 {
    font-weight: 400;
    color: ${baseThemeStyled.colors.body3};
    @media ${baseThemeStyled.breakpoints.medium} {
      display: none;
    }
    @media ${baseThemeStyled.breakpoints.large} {
      //position: relative;
      //top: -2px;
    }
  }

  .red {
    color: ${baseThemeStyled.colors.link};
  }
`

export const Hamburger = styled.div.attrs({
  'data-id': 'hamburger'
})`
  position: relative;
  top: 3px;
  display: none;
  margin-right: 17px;
  @media ${baseThemeStyled.breakpoints.small} {
    display: inline-block;
  }
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    margin-right: 8px;
    top: 0px;
    transform: scale(0.90);
  }
`
export const HamburgerButton = styled(Button).attrs({
  'data-id': 'hamburger-button'
})`
  width: 23px;
  height: 16px;
  position: relative;
  background-color: transparent;
  justify-content: flex-start;
`

export const MobileMenuArea = styled.div.attrs({
  "data-id": "mobile-menu-area"
})`
  position: absolute;
  width: 100%;
  left: 0;
  display: none;
  @media ${baseThemeStyled.breakpoints.small} {
    display: inline-block;
  }
`

export const MobileMenu = styled.div.attrs({
  "data-id": "mobile-menu"
})`
  background-color: #F4F4F4;
  padding: 10px 0 30px 0;
`

export const MobileMenuUl = styled.div.attrs({
  "data-id": "mobile-menu-ul"
})`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
`
export const MobileMenuLi = styled.div.attrs({
  "data-id": "mobile-menu-li"
})`
  &:hover{
    background-color: #fff;
    transition: 0.2s linear background-color;
  }
`

export const MobileMenuLink = styled(Link).attrs({
  "data-id": "mobile-menu-link"
})`
  padding: 13px 16px;
  display: flex;
  align-items: center;
  text-decoration-line: none;
  color: #000;
  span{
    font: 600 14px/16px ${baseThemeStyled.fontsFamily.Lato}
  }
`

export const MobileMenuIcon = styled.div.attrs({
  "data-id": "mobile-menu-icon"
})`
  width: 22px;
  margin-right: 9px;
  svg{
    width: auto;
  }
`

export const MobileMenuIconArrow = styled.div.attrs({
  "data-id": "mobile-menu-icon-arrow"
})`
  margin-left: auto;
  ${MobileMenuLi}:hover & {
    svg path {
      fill: ${baseThemeStyled.colors.link};
    }
  }
`

export const HeaderSearchArea = styled.form.attrs({
  "data-id": "header-main-search-area"
})`
  position: relative;
  padding: 0 14px;
  width: 100%;
`

export const HeaderSearchEl = styled.div.attrs({
  "data-id": "header-main-search"
})`
  display: flex;
  width: inherit;
`

export const HeaderSearchInputArea = styled.div.attrs({
  "data-id": "header-main-search-input-area"
})`
  border-radius: 3px 0 0 3px;
  padding: 12px 9px;
  flex: 1 1 auto;
  background-color: #FFFFFF;
`

export const HeaderSearchInput= styled.input.attrs({
  "data-id": "header-main-search-input"
})`
  width: 100%;
  &::placeholder{
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1px;
  }
`

export const HeaderSearchButton= styled(Button).attrs({
  "data-id": "header-main-search-button"
})`
  background-color: #BD0A2F;
  padding: 13px 16px;
  border-radius: 0 3px 3px 0;
`
