import styled from "styled-components";
import {Button} from "../../../../assets/styles/Main.styled";
import {baseThemeStyled} from "../../../../assets/styles/BaseTheme.styled";

export const MobileFilterComponentEl = styled.div.attrs({
  "data-id": "mobile-filter-component-el"
})`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  .filter-area {
    display: block;
    margin-right: 0;
    background-color: #F4F4F4;
  }
  @media ${baseThemeStyled.breakpoints.small} {
    display: block;
  }
`
export const MobileFilterArea = styled.div.attrs({
  "data-id": "mobile-filter-area"
})`
  padding: 0 16px;

`

export const CloseFilterButton = styled(Button).attrs({
  "data-id": "close-filter-button"
})`
  background-color: transparent;
  position: absolute;
  top: 3px;
  right: 0;
  padding: 10px;
  display: none;
  @media ${baseThemeStyled.breakpoints.small} {
    display: block;
  }
`

export const FilterOverlay = styled.div.attrs({
  "data-id": "filter-overlay"
})`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 position: fixed;
  background-color: rgba(0,0,0,0.15);
`