/* eslint-disable import/no-anonymous-default-export */
import * as VacanciesActions from "./Vacancies/VacanciesSlice"
import * as FiltersActions from "./Filters/FiltersSlice"
import * as UIActions from "./UI/UISlice"

export default {
  ...VacanciesActions.vacanciesActions,
  ...FiltersActions.filtersActions,
  ...UIActions.uiActions
};
