import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Container } from "../../../../assets/styles/Main.styled";
import MainTitle from "../main-title/MainTitle";
import "./style.sass";
import "swiper/css";
import "swiper/css/navigation";
import api from "../../../../api/api";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const MainBrandSlider = () => {
  const [brands, setBrands] = useState<any>([]);
  const [loadBrands, setLoadBrands] = useState(true);

  useEffect(() => {
    api
      .getBrands()
      .then((resp) => {
        setBrands(resp.data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (!brands) return;
    setLoadBrands(false);
  }, [brands]);

  // const navigationPrevRef = useRef(null)
  // const navigationNextRef = useRef(null)

  if (loadBrands)
    return (
      <Container>
        Load...
        <SkeletonTheme baseColor="red" highlightColor="#fff">
          <Skeleton style={{ height: "50px" }} count={1} />
        </SkeletonTheme>
      </Container>
    );

  return (
    <div className="main-brand-slider-area">
      <Container>
        <MainTitle
          mb={25}
          lr_mb={25}
          md_mb={20}
          sm_mb={20}
          esm_mb={15}
          title="Наши партнеры"
          link_title=""
          link_route="/"
        />

        <div className="slider-type-arrow">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 1,
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
            }}
            navigation={
              {
                // Both prevEl & nextEl are null at render so this does not work
                // prevEl: navigationPrevRef?.current,
                // nextEl: navigationNextRef?.current,
              }
            }
            slidesPerGroup={8}
            spaceBetween={15}
            slidesPerView={8}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            className="swiper-with-pagination"
            breakpoints={{
              0: {
                slidesPerView: 2.2,
                spaceBetween: 5,
                slidesPerGroup: 2,
              },
              576: {
                slidesPerView: 4,
                spaceBetween: 10,
                slidesPerGroup: 4,
              },
              768: {
                slidesPerView: 6,
                spaceBetween: 10,
                slidesPerGroup: 6,
              },
              1024: {
                slidesPerView: 8,
                spaceBetween: 15,
                slidesPerGroup: 8,
              },
            }}
          >
            {Object.keys(brands).map((key, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="brands-slide" key={index}>
                    <img src={brands[key].PREVIEW_PICTURE} alt="img" />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/*<div ref={navigationPrevRef} className="swiper-button-prev"/>*/}
          {/*<div ref={navigationNextRef} className="swiper-button-next"/>*/}
        </div>
      </Container>
    </div>
  );
};

export default MainBrandSlider;
