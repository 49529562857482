import styled from "styled-components";
import {ButtonRed} from "../../../../assets/styles/Main.styled";
import {baseThemeStyled} from "../../../../assets/styles/BaseTheme.styled";

export const MainSearchEl = styled.div.attrs({
  "data-id": "main-search-el"
})`
  max-width: 880px;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 32px 84px rgba(0, 0, 0, 0.21725);
  position: relative;
  z-index: 5;
  @media ${baseThemeStyled.breakpoints.large} {
    margin-bottom: 17px;
  }
`

export const MainSearchBody = styled.form.attrs({
  "data-id": "main-search-body"
})`
  max-width: 880px;
  width: 100%;
  background-color: #fff;
  display: flex;
  border-radius: 3px;
  @media ${baseThemeStyled.breakpoints.small} {
    justify-content: space-between;
  }
`

export const MainInputSearch = styled.div.attrs({
  "data-id": "main-input-search"
})`
  display: flex;
  align-items: center;
  padding: 23px 20px;
  flex: 0 0 345px;
  border-radius: 3px 0 0 3px;
  background-color: #fff;
  @media ${baseThemeStyled.breakpoints.medium} {
    padding: 14px 20px;
    &::placeholder{
      font-size: 15px
    }
  }
  @media ${baseThemeStyled.breakpoints.small} {
    flex: 1 1 auto;
    padding: 14px 10px;
  }
  [data-id="header-main-search-input"]{
    &::placeholder{
      font-family: ${baseThemeStyled.fontsFamily.Lato};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
    }
  }
`

export const MainInputSearchIcon = styled.div.attrs({
  "data-id": "main-input-search-icon"
})`
  margin-right: 10px;
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    display: none;
  }
`

export const MainSelectArea = styled.div.attrs({
  "data-id": "main-select-area"
})`
  background-color: #fff;
  padding: 14px 0;
  flex: 1 1 auto;
  //&:after{
  //  content: "";
  //  left: 0;
  //  top: 0;
  //  border
  //}
  @media ${baseThemeStyled.breakpoints.medium} {
    padding: 10px 0px
  }
  @media ${baseThemeStyled.breakpoints.small} {
    display: none;
  }
`

export const MainSelect = styled.div.attrs({
  "data-id": "main-select"
})`
  border-left: 1px dashed #696B77;
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;

  select {
    width: 100%;
  }
`

export const MainSearchButton = styled(ButtonRed).attrs({
  "data-id": "main-search-button"
})`
  font: 700 14px/16px ${baseThemeStyled.fontsFamily.Lato};
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  border-radius: 0 3px 3px 0;
  white-space: nowrap;
  @media ${baseThemeStyled.breakpoints.medium} {
    font: 700 13px/15px ${baseThemeStyled.fontsFamily.Lato};
  }
  svg{
    display: none;
  }
  padding: 15px;
  @media ${baseThemeStyled.breakpoints.extraSmall} {
    svg{
      display: block;
    }
    span{
      display: none;
    }
  }
`
