/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from "react";
import { InterviewContext } from "../../context/interview";
import moment from "moment";
import "moment/locale/ru";
import {
  ContainerGrid,
  FooterBottom,
  FooterBottomText,
  FooterBottomWrapper,
  FooterButton,
  FooterContactsIcon,
  FooterContactsItem,
  FooterContactsLink,
  FooterContactsList,
  FooterLink,
  FooterLogoWrapper,
  FooterNav,
  FooterNavItem,
  FooterNavLink,
  FooterNavList,
  FooterText,
  FooterTop,
  GridButton,
  GridContact,
  GridContactTitle,
  GridLink,
  GridText,
} from "./Footer.styled";
import { Container } from "../../assets/styles/Main.styled";
import { links } from "../Header/header_helper";
import { IconSvg } from "../../utils/shared";

const Footer = () => {
  const { setShowCommentInput, formModal, setFormModal } =
    useContext(InterviewContext);

  const clickButton = () => {
    setShowCommentInput(true);
    setFormModal(!formModal);
  };

  return (
    <footer>
      <FooterTop>
        <ContainerGrid>
          <FooterLogoWrapper>
            {IconSvg.logo.footer("#fff", 248, 25)}
          </FooterLogoWrapper>

          <GridText>
            <FooterText>
              Вы можете задать вопрос на почту, и наш менеджер свяжется с вами
              через некоторое время.
            </FooterText>
          </GridText>

          <GridButton>
            <FooterButton onClick={() => setFormModal(true)}>
              Записаться на собеседование
            </FooterButton>
          </GridButton>

          <GridLink>
            <FooterLink to="/privacy">Пользовательское соглашение</FooterLink>
          </GridLink>

          <FooterNav>
            <FooterNavList>
              {links.map((link, index) => {
                if (link.visible) {
                  return (
                    <FooterNavItem key={index}>
                      <FooterNavLink to={link.route}>
                        {link.title}
                      </FooterNavLink>
                    </FooterNavItem>
                  );
                }
              })}
            </FooterNavList>
          </FooterNav>

          <GridContact>
            <GridContactTitle>Обратная связь</GridContactTitle>
            <FooterContactsList>
              <FooterContactsItem>
                <FooterContactsLink type="tel" to="tel:+79307705070">
                  <FooterContactsIcon>
                    {IconSvg.contact.phone("#fff")}
                  </FooterContactsIcon>
                  +7 (930) 770-50-70
                </FooterContactsLink>
              </FooterContactsItem>
              <FooterContactsItem>
                <FooterContactsLink to="tel:84862442525" type="tel">
                  <FooterContactsIcon>
                    {IconSvg.contact.phone("#fff")}
                  </FooterContactsIcon>
                  8 (4862) 44-25-25 доб. 550
                </FooterContactsLink>
              </FooterContactsItem>
              <FooterContactsItem>
                <FooterContactsLink to="tel:84862210210" type="tel">
                  <FooterContactsIcon>
                    {IconSvg.contact.phone("#fff")}
                  </FooterContactsIcon>
                  8 (4862) 210 210
                </FooterContactsLink>
              </FooterContactsItem>
              <FooterContactsItem>
                <FooterContactsLink
                  to="https://mtkrosberg.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  type="site"
                >
                  <FooterContactsIcon>
                    {IconSvg.contact.site("#fff")}
                  </FooterContactsIcon>
                  mtkrosberg.ru
                </FooterContactsLink>
              </FooterContactsItem>
              <FooterContactsItem>
                <FooterContactsLink type="mail" to="mailto:ok@mtkrosberg.ru">
                  <FooterContactsIcon>
                    {IconSvg.contact.mail("#fff")}
                  </FooterContactsIcon>
                  ok@mtkrosberg.ru
                </FooterContactsLink>
              </FooterContactsItem>
            </FooterContactsList>
          </GridContact>
        </ContainerGrid>
      </FooterTop>

      <FooterBottom>
        <Container>
          <FooterBottomWrapper>
            <FooterBottomText>
              ©2009-{moment(new Date()).format("YYYY")} МТК Росберг_Вакансии.
              Шины и запчасти для строительной и сельхозтехники.
            </FooterBottomText>
          </FooterBottomWrapper>
        </Container>
      </FooterBottom>
    </footer>
  );
};

export default Footer;
