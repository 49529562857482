/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Page, Container, Title } from "assets/styles/Main.styled";
import CardVacancyBigList from "../../component/card-vacancy-big/CardVacancyBigList";
import "./style.sass";
import Breadcrumbs from "../../component/breadcrumbs/Breadcrumbs";
import Search from "./components/search/Search";
import Filter from "./components/filter/Filter";
import HelmetComponent from "../../component/Helmet/Helmet";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useLocation, useNavigate } from "react-router-dom";
import { ILocationState } from "../../utils/types";
import {
  getAllVacancies,
  IVacancie,
  IVacanciesState,
} from "../../redux/Vacancies/VacanciesSlice";
import { useTypedSelector } from "../../hooks/useTypedSelection";
import { getFilters } from "../../redux/Filters/FiltersSlice";
import { IconSvg, parse } from "../../utils/shared";
import {
  MobileFilterButton,
  TitleAreaCatalog,
  TitleCount,
} from "./CatalogPage.styled";
import MobileFilterComponent from "./components/mobile-filter/MobileFilter";
import { IUIState } from "../../redux/UI/UISlice";
import { useActions } from "../../hooks/useActions";

const CatalogPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: ILocationState = useLocation();

  const { changeMobileFilter } = useActions();

  const { mobileFilter }: IUIState = useTypedSelector((state) => state.ui);
  const { vacancies }: IVacanciesState = useTypedSelector(
    (state) => state.vacancies
  );
  const currentPath = location.pathname; // текуший url http://localhost:3000/vacancies

  const search: string = location.search.slice(1); // строка get параметры ?CITY[]=255&CITY[]=253&SECTION_ID[]=350&CHART[]=247

  const [searchField, setSearchField] = useState<string>("");

  const [dropWindowVacancies, setDropWindowVacancies] = useState<
    IVacancie[] | null
  >([]);

  useEffect(() => {
    dispatch(getAllVacancies(search));
    dispatch(getFilters(search));
    if (parse(search)["FIND"]) {
      const find: string = decodeURI(parse(search)["FIND"][0]);
      setSearchField(find);
    } else {
      setSearchField("");
    }
  }, [search]);

  // TODO
  //      - useOutside
  const handleSearchField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchField(e.target.value);
  };

  const Submit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (searchField) {
      navigate(`${currentPath}?FIND=${encodeURI(searchField)}`);
    } else {
      // return
      navigate(`${currentPath}`);
    }
    setDropWindowVacancies([]);
  };

  const handleResetValue = () => {
    setSearchField("");
  };

  return (
    <Page bgColor={"#f4f4f4"}>
      <HelmetComponent title="Вакансии" content="Вакансии" />
      <Breadcrumbs />
      <Container>
        <TitleAreaCatalog>
          <Title>Вакансии</Title>
          <TitleCount>{vacancies.length}</TitleCount>
          <MobileFilterButton onClick={() => changeMobileFilter(true)}>
            {IconSvg.ui.filter()}
            <span>Фильтры</span>
          </MobileFilterButton>
        </TitleAreaCatalog>
      </Container>

      {mobileFilter && (
        <Container>
          <MobileFilterComponent />
        </Container>
      )}

      <Search
        handleResetValue={handleResetValue}
        onSubmit={Submit}
        searchField={searchField}
        handleSearchField={handleSearchField}
      />

      <Container>
        <div className="catalog-area">
          <Filter />
          <CardVacancyBigList />
        </div>
      </Container>
    </Page>
  );
};

export default CatalogPage;
